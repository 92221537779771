import React from "react";

import { useTypedParams } from "react-router-typesafe-routes/dom";
import {
  useEventDetailsBySlug553Query,
  useEventDetailsBySlug556Query,
} from "shared/dist/__generated__/components";
import { allRoutes } from "../../../../util/routes";
import { Helmet } from "react-helmet";
import simmrLogo from "../../../../assets/simmr-logo-red.png";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { useEnv } from "shared/dist/util/env";
import { DateTime } from "luxon";
import { formatDate } from "../../../../util/datetime-formatting";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Text } from "../../../../components/atoms/Text";
import clsx from "clsx";
import Linkify from "linkify-react";
import { Container } from "../../../../widgets/container";
import { SingleFeatureWidget } from "../../../../widgets/single-feature";
import discovery from "../../../../assets/discovery-join.png";
import homescreenHow from "../../../../assets/homescreen-how.png";
import homescreenConnect from "../../../../assets/homescreen-connect.png";
import { Button } from "../../../../components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { YMHPixel } from "../../../../widgets/tracking-pixels";
import mixpanel from "mixpanel-browser";

const PublicEventsDetail: React.FC = () => {
  const { event_slug } = useTypedParams(allRoutes.EVENTS.PUBLIC.DETAILS);
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const { data, loading } = useEventDetailsBySlug556Query({ variables: { url_slug: event_slug! } });
  const baseUri = useEnv("REACT_APP_BASE_URI");

  if (typeof event_slug === "undefined") {
    return <ErrorComp caption="Invalid event slug" />;
  }

  const event = data?.event_public_summaries[0];

  if (!event) return <ErrorComp caption={`Event ${event_slug} not found`} />;

  if (loading) return <SpinnerCentered />;

  const eventImgUrl =
    baseUri.replace(/\/$/, "") +
    allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({
      slug: event.url_slug!,
    });

  return (
    <>
      <YMHPixel />
      <Helmet>
        <meta property="og:title" content={event.name || ""} />
        <meta
          property="og:description"
          content={`Join the Simmr community at ${event.name || ""} ${event.location_description!}`}
        />
        <meta property="og:image" content={eventImgUrl} />
        <meta
          property="og:url"
          content={`${allRoutes.EVENTS.PUBLIC.buildPath({ slug: event.url_slug })}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="min-h-full flex flex-col gap-4 px-6 w-screen bg-[#2A2C38] text-white">
        <div>
          <div className="flex items-center justify-center gap-2 pt-4 mx-8">
            <img className="w-32 sm:w-64" src={simmrLogo} alt="Simmr logo" />
            <h1 className="text-3xl sm:text-6xl tracking-widest font-thin">EVENTS</h1>
          </div>
          {/* <h3 className="px-14 text-md text-center">
        Want to connect with open-minded ENM people in the wild? 😈
      </h3> */}
          <div className="w-5/6 mx-auto flex flex-col items-center">
            <Text as="h3" size={"xl"} weight="thin" className="text-white text-center hidden">
              Connect with open-minded people in the wild! 😈
            </Text>
            <Text as="h3" size={"md"} weight="thin" className="text-white text-center sm:flex">
              Connect with open-minded people in the wild! 😈
            </Text>
          </div>
        </div>
        <div className="flex flex-col gap-4 ">
          <img className="w-[1440px] md:hidden" src={eventImgUrl} />
          <div className="flex flex-col px-6 sm:hidden">
            <Text as="h2" size="md" className="text-white text-center sm:text-start">
              {event.name}
            </Text>
            {/* <h2 className="font-quicksand text-center text-2xl tracking-wider">
            Link up with the Simmr community at
          </h2> */}
            {/* <h2 className="font-quicksand text-center text-2xl font-semibold tracking-wider">
            {event.name}
          </h2> */}
          </div>
        </div>
        <Container size="md">
          <div className="flex flex-col md:bg-[#373A49]  text-xl rounded-lg md:flex-row md:gap-4 md:items-center md:justify-between">
            <div className="md:w-1/2 md:p-6 flex flex-col gap-4">
              <div className="flex-col px-6 hidden sm:flex">
                <Text as="h2" size="md" className="text-white text-center">
                  {event.name}
                </Text>
                {/* <h2 className="font-quicksand text-center text-2xl tracking-wider">
            Link up with the Simmr community at
          </h2> */}
                {/* <h2 className="font-quicksand text-center text-2xl font-semibold tracking-wider">
            {event.name}
          </h2> */}
              </div>
              <p>&#128197; Date: {formatDate(event.start!)}</p>
              <p>&#128205; Location: {event.location_description}</p>
              <div className="flex items-center gap-2">
                {event.event_attendees.slice(0, 3).map(({ user_summary }, i) => (
                  <Avatar
                    key={i}
                    slug={user_summary?.slug!}
                    className="border-2 border-white first:ml-0 -ml-4 inline-block"
                    tailwindSize="8"
                  />
                ))}
                <p className="text-lg text-[#FE635F]">
                  {event.event_attendees.length < 25 ? "25" : event.event_attendees.length}+ members
                  attending
                </p>
              </div>
              <div className="relative">
                <div
                  className={clsx("overflow-hidden", expanded ? "line-clamp-none" : "line-clamp-3")}
                >
                  {event.description?.split("\n").map((line, i) => (
                    <Linkify
                      options={{
                        target: "_blank",
                        className: "whitespace-pre-line link font-semibold",
                      }}
                      as="p"
                      key={i}
                    >
                      {line}
                      {"\u00A0"}
                    </Linkify>
                  ))}
                </div>
                <a
                  className="cursor-pointer inline ml-auto text-bold text-[#FE635F]"
                  onClick={() => {
                    setExpanded(!expanded);
                    mixpanel.track("Event Description Expanded", { event_id: event.id });
                  }}
                >
                  {expanded ? "Show Less" : "Show More"}
                </a>
              </div>
              <div className="flex items-center justify-center">
                <Button
                  variant="standard"
                  // size="md"
                  className="w-2/3 sm:w-2/3 lg:w-2/3 mt-5"
                  onClick={() => navigate(allRoutes.PROMO.buildPath({ partner_id: event.id! }))}
                >
                  JOIN THE EVENT
                </Button>
              </div>
            </div>
            <img className="hidden md:flex md:w-1/2 rounded-r-lg" src={eventImgUrl} />
          </div>
        </Container>
        <Container size="md" className="flex flex-col items-center">
          <Text as="h3" size="lg" className="text-white text-center mt-6 mb-4 hidden sm:flex">
            New to Simmr?
          </Text>
          <Text as="h3" size="md" className="text-white text-center mt-6 mb-4 sm:hidden">
            New to Simmr?
          </Text>
          <SingleFeatureWidget imgSrc={homescreenConnect} right={false}>
            <div className="space-y-4 max-w-md">
              <Text as="h3" weight="semibold" className="text-white leading-tight mb-2">
                Nope. Not "just another dating app"
              </Text>
              <div className="space-y-2">
                <Text as="p" size="sm" weight="regular" className="leading-tight inline">
                  Simmr is where{" "}
                  <span className="bg-[#FD6461] px-2">
                    trust meets connection for the ENM community.
                  </span>
                </Text>
                <Text as="p" size="sm" weight="regular" className="leading-snug block">
                  With built-in verification and vouching, you can focus on staying in touch,
                  finding events, and meeting new people who truly get you. It's more than
                  connecting; it's about belonging
                </Text>
              </div>
              <div className="flex items-center justify-center">
                <Button
                  variant="standard"
                  // size="md"
                  className="w-2/3 mt-5"
                  onClick={() => navigate(allRoutes.PROMO.buildPath({ partner_id: event.id! }))}
                >
                  JOIN THE EVENT
                </Button>
              </div>
            </div>
          </SingleFeatureWidget>
          <SingleFeatureWidget imgSrc={homescreenHow} right={true}>
            <div className="space-y-6 max-w-xl">
              {/* Header */}
              <Text as="h3" weight="semibold" className="text-white leading-tight mb-2 ml-10">
                How Simmr Works 🔥
              </Text>

              {/* Features List */}
              <ul className="space-y-6">
                <li className="flex items-center space-x-3">
                  <span className="text-2xl">😈</span>
                  <div>
                    <Text as="p" size="sm" weight="regular" className="leading-tight inline">
                      <span className="font-semibold">Create a profile</span>{" "}
                      <span className="text-white">
                        that represents you and link with your partner's profile for a shared
                        experience.
                      </span>
                    </Text>
                  </div>
                </li>
                <li className="flex items-center space-x-3">
                  <span className="text-2xl">🧡</span>
                  <div>
                    <Text as="p" size="sm" className="leading-tight">
                      <span className="font-semibold">Add friends and matches</span>{" "}
                      <span className="text-white">
                        —build your community by connecting with verified people who understand and
                        celebrate ENM.
                      </span>
                    </Text>
                  </div>
                </li>
                <li className="flex items-center space-x-3">
                  <span className="text-2xl">🎉</span>
                  <div>
                    <Text as="p" size="sm" className="leading-tight">
                      <span className="font-semibold">Attend exclusive ENM events</span>{" "}
                      <span className="text-white">
                        or create your own. Connect with like-minded people in your area or around
                        the world.
                      </span>
                    </Text>
                  </div>
                </li>
              </ul>

              {/* Call to Action */}
              <a href="https://simmr.typeform.com/launch">
                <Button variant="standard" className="mt-4">
                  JOIN SIMMR NOW
                </Button>
              </a>
            </div>
          </SingleFeatureWidget>
          <SingleFeatureWidget imgSrc={discovery} right={false}>
            {/* <H3 className="text-primary-content normal-case">
                Connect with real, verified members
              </H3> */}
            <Text as="h3" weight="semibold" className="text-white leading-tight mb-2">
              Join Thousands of Verified Members
            </Text>
            <Text as="p" size="sm" weight="regular" className="leading-tight inline">
              <span className="font-semiboldd ">
                No bots, no fakes—just real people who value genuine connections.
              </span>{" "}
              Every Simmr member goes through a verification process to keep our community authentic
              and trusted.{" "}
            </Text>
            <div className="flex items-center justify-center">
              <Button
                variant="standard"
                // size="md"
                className="w-2/3 mt-5"
                onClick={() => navigate(allRoutes.PROMO.buildPath({ partner_id: event.id! }))}
              >
                JOIN THE EVENT
              </Button>
            </div>
          </SingleFeatureWidget>
        </Container>
      </div>
    </>
  );
};

export default PublicEventsDetail;
