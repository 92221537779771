import { FacebookPixelComponent, GoogleComponent } from "./widgets/tracking-pixels";
import { useEnv, useIsDev } from "shared/dist/util/env";

import React, { use } from "react";
import { SmsAuthForm } from "shared-web-react/dist/sms-login-screen";
import { allRoutes } from "./util/routes";
import login0 from "./assets/login-0.jpg";
import login1 from "./assets/login-1.jpg";
import login2 from "./assets/login-2.jpg";
import login3 from "./assets/login-3.jpg";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { useEventSlugFromId556Query } from "shared/dist/__generated__/components";

export function UserSmsAuth(): React.JSX.Element {
  const navigate = useNavigate();
  const { partner_id } = useTypedParams(allRoutes.PROMO);

  // React.useEffect(() => {
  //   if (eventId) {
  //     try {
  //       const { data } = useEventSlugFromId556Query({
  //         variables: { event_id: eventId },
  //         skip: !eventId,
  //       });
  //       const { url_slug } = data?.events_by_pk || {};
  //       mixpanel.track(`User at login page with event slug: ${url_slug}`);
  //     } catch (e) {
  //       console.error("MP Error: ", e);
  //     }
  //   } else {
  //     mixpanel.track("User at login page");
  //   }
  // }, [eventId]);

  const isDev = useIsDev();
  const env = useEnv("REACT_APP_ENV");
  const loginImg = React.useMemo(() => {
    const arr = [login0, login1, login2, login3];
    return arr[Math.round(Math.random() * (arr.length - 1))];
  }, []);

  return (
    <div className="bg-[#2A2C38] min-h-screen flex flex-col font-sf pt-safe pb-safe">
      <div className="p-5 pb-0">
        <button className="text-white text-3xl" onClick={() => history.back()}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div className="flex flex-1 items-center justify-center">
        <SmsAuthForm
          allowDevLoginEnv={isDev ? env : undefined}
          redirectToOnLogin={allRoutes.HOME.buildPath({})}
          eventId={partner_id ?? ""}
        />
        {/* <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={loginImg}
            alt="stock photo of people together"
          />
        </div> */}
        <FacebookPixelComponent />
        <GoogleComponent />
      </div>
    </div>
  );
}
