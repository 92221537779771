import React from "react";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { useSetPageTitle } from "shared-web-react/dist/widgets/nav-stack-view";
import { allRoutes } from "../../../util/routes";
import { useEventDetailsBySlug553Query } from "shared/dist/__generated__/components";
import { useMyId } from "shared/dist/auth-data";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Link } from "react-router-dom";

const EventAttendees: React.FC = () => {
  useSetPageTitle("Members Attending");
  const myId = useMyId();
  const { slug } = useTypedParams(allRoutes.EVENT_FROM_SLUG.ATTENDEES);
  const { data, loading } = useEventDetailsBySlug553Query({
    skip: !slug || !myId,
    variables: { url_slug: slug! },
  });

  if (loading) return <SpinnerCentered />;

  const eventData = data?.events?.[0] ?? data?.event_public_summaries?.[0];

  return (
    <div className="grid grid-cols-3 gap-4 text-white bg-[#2A2C38] p-4">
      {eventData?.event_attendees.map((at, i) => (
        <Link
          to={allRoutes.PROFILE.buildPath({ slug: at.user_summary?.slug! })}
          key={i}
          className="flex items-center justify-center flex-col gap-4"
        >
          <Avatar slug={at.user_summary?.slug!} tailwindSize="24" />
          <p className="text-md text-[#797A81] text-center">{at.user_summary?.screen_name}</p>
        </Link>
      ))}
    </div>
  );
};

export default EventAttendees;
