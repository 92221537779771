import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RefetchProp, UserPageContainer } from "../common";
import { UserPageTab, UserPageTabLabel } from "../../../widgets/tabs";
import {
  faC,
  faCircleEnvelope,
  faCircleInfo,
  faHeartCirclePlus,
  faLock,
  faPerson,
} from "@fortawesome/pro-solid-svg-icons";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { H4 } from "shared-web-react/dist/widgets/text";
import React, { useEffect } from "react";
import { SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../../../util/routes";
import { useMySlug } from "../../bio/bio-settings";
import { useNeedsEmailOrVerification } from "../../../widgets/pickers/email-editor";
import { Text } from "../../../components/atoms/Text";
import { useSetPageTitle } from "shared-web-react/dist/widgets/nav-stack-view";

export type UserSettingsProps = {} & RefetchProp;

// export function DetailsSettings(): React.JSX.Element {
//   return (
//     <div className="pb-[40vh] space-y-2">
//       <BirthdayPicker />
//       <div className="divider divider-vertical w-1/2" />
//       <GenderPicker />
//       <div className="divider divider-vertical w-1/2" />
//       <PronounsPicker />
//       <div className="divider divider-vertical w-1/2" />
//       <OrientationPicker />
//       <div className="divider divider-vertical w-1/2" />
//       <LocationSettings />
//       <div className="divider divider-vertical w-1/2" />
//     </div>
//   );
// }

// Profile Personal Dating Privacy
export function SettingsIndexPage(): React.JSX.Element {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate(allRoutes.SETTINGS.PROFILE.buildPath({}));
  }, [navigate]);
  return <SpinnerFullScreen />;
}

const SettingsTabs: Array<{
  route: string;
  icon: FontAwesomeIconProps["icon"];
  label: string;
}> = [
  {
    label: "Profile",
    route: allRoutes.SETTINGS.PROFILE.buildPath({}),
    icon: faCircleInfo,
  },
  {
    label: "Personal",
    route: allRoutes.SETTINGS.PERSONAL.buildPath({}),
    icon: faPerson,
  },
  {
    label: "Dating",
    route: allRoutes.SETTINGS.DATING.buildPath({}),
    icon: faHeartCirclePlus,
  },
  {
    label: "Privacy",
    route: allRoutes.SETTINGS.PRIVACY.buildPath({}),
    icon: faLock,
  },
];

export function UserSettings({ refetch }: UserSettingsProps): React.JSX.Element {
  const showEmailBadge = useNeedsEmailOrVerification();
  useSetPageTitle("Edit Profile");
  const useNewLayout = true;
  const { slug } = useMySlug() ?? {};
  const location = useLocation();

  useEffect(() => {
    // Scroll the entire page to the top
    window.scrollTo(0, 0);

    // Optional: Reset scroll for specific sections
    const container = document.querySelector(".sections-container");
    if (container) {
      container.scrollTop = 0;
    }
  }, [location.pathname]);

  return (
    <div className="max-w-screen relative pt-5 bg-[#2A2C38] px-6">
      <div className="fixed top-0 bottom-0 right-0 left-0 bg-base-200 -z-10" />

      <Outlet />
    </div>
  );
}
