import {
  SelectWithDetails,
  SelectWithDetailsOption,
} from "shared-web-react/dist/widgets/select-details";

import { ContentBoxInfoLine } from "../../../../widgets/content-box";
import { DiscoveryCommonProps } from "./dating-settings-utilities";
import { H5 } from "shared-web-react/dist/widgets/text";
import { RangeSlider } from "shared-web-react/dist/widgets/range-slider";
import React from "react";
import { classNames } from "shared/dist/util";
import { distanceOptionsKeyedInMeters } from "shared-web-react/dist/util/units";
import { useDebounce } from "use-debounce";
import { Text } from "../../../../components/atoms/Text";
import { Button } from "../../../../components/atoms/Button";

export function DistanceSettings({
  prefs,
  disabled,
  onUpdate,
}: DiscoveryCommonProps): React.JSX.Element {
  const [distance, setDistance] = React.useState<number | undefined>(undefined); // Start undefined
  const isInitialized = React.useRef(false);

  // Sync state with `prefs.max_distance` when data loads
  React.useEffect(() => {
    if (prefs?.max_distance !== undefined && prefs.max_distance !== distance) {
      setDistance(prefs.max_distance); // Sync with prefs
    }
  }, [prefs?.max_distance]);

  const [debouncedDistance] = useDebounce(distance, 500);

  // Update preferences when debounced distance changes
  React.useEffect(() => {
    if (isInitialized.current && !disabled && debouncedDistance !== prefs?.max_distance) {
      onUpdate({ max_distance: debouncedDistance });
    }
  }, [debouncedDistance, disabled, prefs?.max_distance, onUpdate]);

  // Mark as initialized after first render
  React.useEffect(() => {
    if (distance !== undefined) {
      isInitialized.current = true;
    }
  }, [distance]);

  return (
    <div>
      <Text as="h3" size="xs" weight="semibold">
        Distance Range
      </Text>
      <div className="flex items-center space-x-2">
        {/* Use single value for RangeSlider */}
        <div className="w-full">
          <RangeSlider
            min={1}
            max={200}
            value={distance ?? 200}
            onChange={(v: number) => setDistance(v)}
            disabled={disabled || distance === undefined}
            className={classNames("h-8", disabled && "opacity-50 cursor-not-allowed")}
          />
        </div>
        <Button
          size="xs"
          variant="standard"
          className="text-gray-400"
          onClick={() => setDistance(10000)}
          disabled={disabled || distance === 10000}
        >
          Any
        </Button>
      </div>

      <Text as="p" size="xs" className="text-gray-400 mt-1">
        {distance && distance > 3000 ? "Showing All Distances" : `${distance} mi`}{" "}
      </Text>
    </div>
  );
}

// export function AgeRangeSettings({
//   disabled,
//   onUpdate,
//   prefs,
// }: DiscoveryCommonProps): React.JSX.Element {
//   const my_id = useMyId();
//   const [ageRange, setAgeRange] = React.useState<null | [number, number]>(null);
//   React.useEffect(() => {
//     if (!prefs || ageRange) { return; } // prettier-ignore
//     setAgeRange([prefs.min_age, prefs.max_age]);
//   }, [prefs, ageRange, setAgeRange]);
//   const [mutate] = useUpdateDiscoveryPreferences300Mutation();
//   const [debouncedAgeRange] = useDebounce(ageRange, 500);
//   React.useEffect(() => {
//     if (
//       disabled ||
//       !my_id ||
//       !debouncedAgeRange ||
//       (debouncedAgeRange[0] === prefs?.min_age && debouncedAgeRange[1] === prefs?.max_age)
//     ) {
//       return;
//     }
//     const update = {
//       min_age: debouncedAgeRange[0],
//       max_age: debouncedAgeRange[1],
//     };
//     onUpdate(update);
//   }, [mutate, debouncedAgeRange]);
//   return (
//     <div className="text-white">
//       <span className="flex items-center justify-between">
//         <Text as="h3" size="xs" weight="semibold" className="pt-2">
//           Age Range
//         </Text>
//         <Text as="h3" size="xxs" weight="semibold" className="pt-2">
//           {ageRange ? ageRange[0] : 18} - {ageRange ? ageRange[1] : 99}
//         </Text>
//       </span>
//       <div className={classNames("py-2")}>
//         <RangeSlider
//           min={18}
//           max={99}
//           className={classNames("h-8", disabled && "opacity-50")}
//           value={ageRange ?? [18, 99]}
//           onChange={(v) => v && setAgeRange([v[0], v[1]])}
//           pearling
//           minDistance={2}
//         />
//       </div>
//     </div>
//   );
// }
