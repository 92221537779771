import {
  GenderListQueryResult,
  GqlOps,
  L_Geo_Point_Type,
  L_Relationship_Type_Enum,
  Profile_Privacy_Levels_Enum,
  ProfilePromptsQuery,
  useAddPromptAnswersMutation,
  useBioV2FromSlugBasics554Query,
  useBioV2FromSlugRelationshipsQuery,
  useBirthdayQuery,
  useGenderListQuery,
  useProfilePromptsQuery,
  useSetUrlMutation,
  useUpdateBioMutation,
  useUpdateUserTaglineMutation,
  useUpdateOrientationMutation,
  useUpdateScreenNameMutation,
  useUpdateUserGenderMutation,
  useUpdateUserPronounsMutation,
  useUserLocations500Query,
  useSetEmailMutation,
  useGetMyEmailQuery,
  useSearchLocationsLazyQuery,
} from "shared/dist/__generated__/components";
import {
  LocationPicker,
  LocationSettings,
  useSaveLocation,
} from "../../../widgets/bio/location-picker";
import {
  SelectWithDetails,
  SelectWithDetailsOption,
} from "shared-web-react/dist/widgets/select-details";

import { Container } from "../../../widgets/container";
import { ContentBox } from "../../../widgets/content-box";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { GenderPickerForUsers } from "../../../widgets/pickers/gender-picker";
import { H4 } from "shared-web-react/dist/widgets/text";
import { HopeToFindPicker } from "../../../widgets/pickers/hope-to-find-picker";
import { orientations } from "../../../widgets/bio/orientation-picker";
import { PronounsPicker } from "../../../widgets/bio/pronouns-picker";
import React, { useEffect, useRef, useState } from "react";
import { SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";
import { ValidatedInput } from "../../../widgets/inputs/validated-input";
import { classNames } from "shared/dist/util";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useForm } from "react-hook-form";
import { allRoutes, useMustBeLoggedIn } from "../../../util/routes";
import { z } from "zod";
import { Text } from "../../../components/atoms/Text";
import { useMyInfo } from "../../bio/bio-settings";
import { match } from "ts-pattern";
import clsx from "clsx";
import { P } from "ts-pattern";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons";
import { DateTime } from "luxon";
import { useAllDiscoveryPreferences } from "./dating/dating-settings-utilities";
import { useNavigate } from "react-router-dom";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Button } from "../../../components/atoms/Button";
import { Combobox } from "@headlessui/react";
import { useDebounce } from "use-debounce";
import { Loc } from "shared/dist/util/loc";
import { IdentitySettings } from "./identity";
import { ImageUploader } from "../../../widgets/images";
import { useProfilePageEditFunctions } from "../edit-functions";

export type EditFieldProps = {
  field: string | null | undefined;
  value: string | null | undefined;
  handleUpdate: (value: string | Profile_Privacy_Levels_Enum) => Promise<void>;
  options?: Array<string>;
  description?: string;
  maxLength?: number;
  isLocationField?: boolean;
};

export function ProfileSettings({
  onNext,
  dontLoadSavedAnswers,
}: {
  onNext: () => void;
  dontLoadSavedAnswers?: boolean;
}): React.JSX.Element {
  const [hidden, setHidden] = React.useState(false);
  const [fieldToEdit, setFieldToEdit] = React.useState<{
    field: string | null | undefined;
    value: string | null | undefined;
    maxLength?: number | null | undefined;
    handleUpdate: (value: string) => void;
    options?: Array<string>;
    description?: string;
    isLocationField?: boolean;
  }>({
    field: null,
    value: null,
    handleUpdate: () => {},
    options: [],
    description: "",
    isLocationField: false,
  });

  // useSetBackOverride(() => {
  //   if (fieldToEdit.field) {
  //     setFieldToEdit({ field: null, value: null, handleUpdate: () => {} });
  //     setHidden(false);
  //     return true;
  //   } else {
  //     window.history.back();
  //     return false;
  //   }
  // });

  const my_id = useMustBeLoggedIn() ?? "";
  const { slug, loading: infoLoading, status, refetch } = useMyInfo();
  const { data: birhdayData, loading } = useBirthdayQuery({
    variables: { user_id: my_id },
  });
  const genderData = useGenderListQuery();

  const { data: bioData = null, loading: bioLoading } = useBioV2FromSlugBasics554Query({
    variables: slug ? { slug } : { slug: "" },
    fetchPolicy: "cache-and-network",
  });
  const navigate = useNavigate();
  const { data: relsData = null, loading: relsLoading } = useBioV2FromSlugRelationshipsQuery({
    variables: slug ? { slug } : { slug: "" },
    fetchPolicy: "cache-first",
  });

  const { data: preferences, loading: preferencesLoading } = useAllDiscoveryPreferences(my_id);
  const editFunctions = useProfilePageEditFunctions(my_id, true, async () => {
    refetch?.();
  });
  const {
    error: promptsError,
    loading: promptsLoading,
    data: promptsData,
  } = useProfilePromptsQuery({ variables: { my_id } });
  const {
    register,
    setValue,
    formState: { errors },
  } = useForm<Record<string, string>>({
    mode: "all",
    defaultValues: { birthday: "1990-01-01" },
  });
  React.useEffect(() => {
    if (dontLoadSavedAnswers) {
      return;
    }
    promptsData?.profile_prompts?.forEach((p) => {
      const val = p?.answers?.[0]?.value;
      if (!val) return;
      setValue(p.id, val);
    });
  }, [dontLoadSavedAnswers, promptsData?.profile_prompts, setValue]);

  const partners = match(relsData?.l_bio_v2)
    .with({ __typename: "L_Bio_V2_Success" }, (bio) => bio.relationships)
    .otherwise(() => [])
    .filter((f) => f.type === L_Relationship_Type_Enum.Partner)
    .map((p) =>
      p?.slug && p?.screen_name
        ? {
            slug: p.slug,
            screenName: p.screen_name,
          }
        : null
    );

  const bio = match(bioData?.l_bio_v2)
    .with({ __typename: "L_Bio_V2_Success" }, (bio) => bio)
    .otherwise(() => null);

  if (promptsError) {
    return <ErrorComp caption={promptsError.clientErrors.toString()} />;
  }

  if (infoLoading || bioLoading || relsLoading || promptsLoading || !slug) {
    return <SpinnerFullScreen />;
  }

  const { gender, orientation, bio: userBio, tagline } = bio?.details ?? {};

  console.log("bio", bio?.details);
  const formattedDateOfBirth = DateTime.fromISO(birhdayData?.users_by_pk?.birthday ?? "").toFormat(
    "MM/dd/yyyy"
  );

  const editField = ({
    field,
    value,
    handleUpdate,
    options,
    description,
    isLocationField,
  }: EditFieldProps) => {
    setFieldToEdit({ field, value, handleUpdate, options, description, isLocationField });
    setHidden(true);
  };

  const relStatus = promptsData?.profile_prompts?.find((p) => {
    if (p.__typename === "profile_prompts" && p.question_title?.en_US === "Relationship Status") {
      return p.answers?.[0]?.value;
    }
  });

  return (
    <Container size="xs" className="flex flex-col !h-full gap-2 pb-20 items-stretch">
      {!hidden ? (
        <>
          <div className="w-full  flex flex-col items-center">
            <ImageUploader
              disallowMultiple
              // btnClassName={clsx(
              //   "btn-circle btn-primary",
              //   "flex-1 border-none flex justify-center items-center"
              // )}
              iconClassName="hidden"
              btnClassName={classNames(
                "focus-none min-h-24 flex flex-col items-center border-none bg-transparent text-gray-300 transition-all"
              )}
              mediaOptions={{
                exclude_from_bio: true,
                exclude_from_feed: true,
              }}
              showPreviewConfirmation={true}
              onComplete={async (s) => {
                console.log("🚀 ~ file: thumbnails.tsx ~ line 97 ~ onComplete ~ s", s);
                await editFunctions?.setProfileImage(s);
                window.location.href = allRoutes.SETTINGS.PROFILE.buildPath({});
              }}
              btnContent={
                <div className="flex flex-col items-center">
                  {slug && <Avatar slug={slug} tailwindSize="24" />}
                  <Text as="h3" size="xs" className="pt-2" weight="semibold">
                    Edit profile picture
                  </Text>
                </div>
              }
              onError={() => {
                console.log("🚀 ~ file: thumbnails.tsx ~ line 101 ~ onError");
              }}
            />
          </div>
          <IdentitySettings />
          <ProfileSettingsSection bio={userBio} tagline={tagline} handleClick={editField} />
          <LocationSettingsSection handleClick={editField} />
          <PersonalSettingsSection
            gender={gender ?? ""}
            genderData={genderData}
            orientation={orientation ?? ""}
            dataOfBirth={formattedDateOfBirth}
            slug={slug}
            screenName={bio?.user?.screen_name ?? ""}
            pronouns={bio?.details?.pronouns ?? ""}
            handleClick={editField}
          />
          <MoreSettingsSection />
          {/* <RelationshipSettingsSection handleClick={editField} promptsData={promptsData} /> */}
        </>
      ) : (
        <div>
          <EditProfileSettingBubble
            text={fieldToEdit.field ?? ""}
            value={fieldToEdit.value}
            options={fieldToEdit?.options || []}
            maxLength={fieldToEdit?.maxLength ?? undefined}
            description={fieldToEdit.description || ""}
            isLocationField={fieldToEdit.isLocationField}
            onBack={() => {
              setHidden(false);
              setFieldToEdit({ field: null, value: null, handleUpdate: () => {} });
            }}
            onSave={async (updatedValue) => {
              console.log("updatedValue", updatedValue);
              setHidden(false);
              await fieldToEdit.handleUpdate(updatedValue);
              setFieldToEdit({ field: null, value: null, handleUpdate: () => {} });
              return Promise.resolve();
            }}
          />
        </div>
      )}
    </Container>
  );
}
function ProfileSettingsSection({
  bio,
  tagline,
  handleClick,
}: {
  bio: string | null | undefined;
  tagline: string | null | undefined;
  handleClick: ({ field, value, description, maxLength }: EditFieldProps) => void;
}): React.JSX.Element {
  const my_id = useMustBeLoggedIn() ?? "";
  const [mutate, { loading: updateLoading }] = useUpdateBioMutation();
  const [updateTagline, { loading: updateLoadingTagline }] = useUpdateUserTaglineMutation();
  const handleUpdate = async (value: string) => {
    await mutate({
      variables: { bio: value, id: my_id },
      refetchQueries: [GqlOps.Query.BioV2FromSlugBasics554],
    });
  };

  const handleTaglineUpdate = async (value: string) => {
    await updateTagline({
      variables: { tagline: value, id: my_id },
      refetchQueries: [GqlOps.Query.BioV2FromSlugBasics554],
    });
  };

  if (updateLoading) {
    return <SpinnerFullScreen />;
  }

  return (
    <>
      <Text as="h3" size="xs" weight="semibold" className="pt-2">
        Profile Settings
      </Text>
      <ProfileSettingBubble
        text="Tag Line:"
        value={tagline ? tagline.slice(0, 24) + "..." : ""}
        handleClick={() => {
          handleClick({
            field: "Tag Line:",
            value: tagline,
            handleUpdate: handleTaglineUpdate,
            description: "",
          });
        }}
      />

      <ProfileSettingBubble
        text="Bio:"
        value={bio ? bio.slice(0, 24) + "..." : ""}
        handleClick={() => {
          handleClick({
            field: "Bio:",
            value: bio,
            handleUpdate,
            description: "",
            maxLength: 500,
          });
        }}
      />
    </>
  );
}

function PersonalSettingsSection({
  screenName,
  gender,
  pronouns,
  orientation,
  genderData,
  dataOfBirth,
  slug,
  handleClick,
}: {
  gender: string;
  slug: string;
  screenName: string;
  genderData: GenderListQueryResult;
  dataOfBirth: string;
  pronouns: string;
  orientation: string;
  handleClick: ({ field, value, options }: EditFieldProps) => void;
}): React.JSX.Element {
  const user_id = useMustBeLoggedIn() ?? "";
  const [updateScreenNameMutaion, { loading: updateLoading }] = useUpdateScreenNameMutation();
  const [setUrlMutation] = useSetUrlMutation();
  const [setOrientationMutation] = useUpdateOrientationMutation();
  const [setPronounMutation, saveMutationResult] = useUpdateUserPronounsMutation();
  const [setGenderMutation] = useUpdateUserGenderMutation();
  const saveLocation = useSaveLocation({ source: L_Geo_Point_Type.Manual });

  const setSlug = async (value: string) => {
    const resultSlug = (
      await setUrlMutation({
        refetchQueries: [GqlOps.Query.BioV2FromSlugBasics554],
        variables: {
          id: user_id,
          slug: value,
        },
      })
    )?.data?.update_user_url_slugs?.returning?.[0]?.slug;
    console.log("🚀 ~ file: url-slug-form.tsx:72 ~ resultSlug:", resultSlug);
  };

  const setScreenName = async (value: string): Promise<void> => {
    const result = (
      await updateScreenNameMutaion({
        variables: {
          id: user_id,
          screen_name: value,
        },
        refetchQueries: [GqlOps.Query.BioV2FromSlugBasics554],
      })
    )?.data?.update_users_by_pk?.screen_name;
    if (!result) {
      console.error("Unable to save screen name");
    }
  };

  const setOrientation = async (value: string) => {
    await setOrientationMutation({
      variables: {
        orientation: value,
        user_id,
      },
      refetchQueries: [GqlOps.Query.BioV2FromSlugBasics554],
    });
  };

  const setPronoun = async (value: string) => {
    await setPronounMutation({
      variables: {
        id: user_id,
        pronouns: value,
      },
      refetchQueries: [GqlOps.Query.BioV2FromSlugBasics554],
    });
  };
  const setGender = async (value: string) => {
    const selectedGenderId = genderData.data?.genders.find((g) => g.name.en_US === value)?.id;
    if (!selectedGenderId) return;
    await setGenderMutation({
      variables: {
        id: user_id,
        gender_id: selectedGenderId,
      },
      refetchQueries: [GqlOps.Query.BioV2FromSlugBasics554],
    });
  };

  const [setEmailMutation] = useSetEmailMutation();
  const { data, loading } = useGetMyEmailQuery({
    variables: { id: user_id! },
    skip: !user_id,
  });

  const myEmail = data?.users_by_pk?.email;
  const handleUpdateEmail = React.useCallback(
    async (value: string) => {
      if (!user_id) return;

      try {
        const result = (
          await setEmailMutation({
            variables: {
              id: user_id,
              email: value,
            },
          })
        )?.data?.update_users_by_pk?.email;
        if (!result) {
          console.error("Unable to save email");
        }
      } finally {
      }
    },
    [setEmailMutation]
  );

  const profileOptions = [
    {
      text: "Name:",
      value: screenName,
      description:
        "To help people discover you, use the name you're known for. You can only change your name twice every two weeks. \n\nAny person in and outside of Simmr can see your name.",
      handleClick: () =>
        handleClick({ field: "Name:", value: screenName, handleUpdate: setScreenName }),
    },
    {
      text: "Username:",
      value: slug,
      description:
        "Your username is unique to you and can be used to find you on Simmr. You can only change your username once every two weeks. \n\nAny person in and outside of Simmr can see your username.",
      handleClick: () => handleClick({ field: "Username:", value: slug, handleUpdate: setSlug }),
    },
    // {
    //   text: "Date of Birth:",
    //   value: dataOfBirth,
    //   handleClick: () => handleClick({ field: "Date of Birth:", value: dataOfBirth }),
    // },
    {
      text: "Email:",
      value: myEmail,
      handleClick: () =>
        handleClick({ field: "Email:", value: myEmail, handleUpdate: handleUpdateEmail }),
    },
    {
      text: "Pronouns:",
      value: pronouns,
      handleClick: () =>
        handleClick({ field: "Pronouns:", value: pronouns, handleUpdate: setPronoun }),
    },
    {
      text: "Gender:",
      value: gender,
      handleClick: () =>
        handleClick({
          field: "Gender:",
          value: gender,
          handleUpdate: setGender,
          options: genderData.data?.genders.map((gender) => gender.name.en_US) || [],
        }),
    },
    {
      text: "Orientation:",
      value: orientation,
      handleClick: () =>
        handleClick({
          field: "Orientation:",
          value: orientation,
          handleUpdate: setOrientation,
          options: Object.values(orientations).map((o) => o[0]),
        }),
    },
  ];

  return (
    <>
      <Text as="h3" size="xs" weight="semibold" className="pt-2">
        Personal Settings
      </Text>
      {profileOptions.map((item) => (
        <ProfileSettingBubble
          key={item.text}
          text={item.text}
          value={item.value}
          handleClick={item.handleClick}
        />
      ))}
    </>
  );
}

function MoreSettingsSection({}: {}): React.JSX.Element {
  const navigate = useNavigate();
  return (
    <>
      <Text as="h3" size="xs" weight="semibold" className="pt-2">
        More Settings
      </Text>

      <ProfileSettingBubble
        text="Account & Privacy Settings"
        handleClick={() => navigate(allRoutes.SETTINGS.PRIVACY.buildPath({}))}
      />
      <ProfileSettingBubble
        text="Dating Preferences"
        handleClick={() => navigate(allRoutes.SETTINGS.DATING.buildPath({}))}
      />
    </>
  );
}

// function RelationshipSettingsSection({
//   handleClick,
//   promptsData,
// }: {
//   handleClick: ({ field, value }: EditFieldProps) => void;
//   promptsData: ProfilePromptsQuery | undefined;
// }): React.JSX.Element {
//   const [addAnswersMutation] = useAddPromptAnswersMutation();

//   const onSave = React.useCallback(
//     async (value: string, prompt: any) => {
//       if (prompt && typeof prompt === "object" && "id" in prompt) {
//         addAnswersMutation({
//           refetchQueries: [GqlOps.Query.ProfilePrompts],
//           variables: {
//             answers: [
//               {
//                 prompt_id: prompt.id,
//                 show_on_bio: true,
//                 value,
//               },
//             ],
//           },
//         });
//       }
//     },
//     [addAnswersMutation]
//   );

//   const relationShipItems =
//     promptsData?.profile_prompts?.map((prompt) => {
//       const value = prompt.answers?.[0]?.value;
//       return {
//         text: prompt.question_title?.en_US ?? "",
//         value: value,
//         handleClick: () =>
//           handleClick({
//             field: prompt.question_title?.en_US,
//             value: value,
//             handleUpdate: (updatedValue) => onSave(updatedValue, prompt),
//             options: prompt.question_answer_choices?.en_US?.split("|") ?? [],
//           }),
//       };
//     }) ?? [];

//   return (
//     <>
//       <Text as="h3" size="xs" weight="semibold" className="pt-2">
//         Relationship Information
//       </Text>
//       {relationShipItems.map((item) => (
//         <ProfileSettingBubble
//           key={item.text}
//           text={item.text}
//           value={item.value}
//           handleClick={item.handleClick}
//         />
//       ))}
//     </>
//   );
// }

export function ProfileSettingBubble({
  text,
  value,
  handleClick,
  description,
  isExpanded, // New prop to control chevron direction
}: {
  text: string;
  value?: string | React.ReactNode; // Accept string or chevron icons
  handleClick?: () => void;
  description?: string;
  isExpanded?: boolean; // Pass true when expanded
}): React.JSX.Element {
  return (
    <div
      className="flex justify-between items-center py-2 my-1 bg-[#464752] rounded-lg px-4 cursor-pointer"
      onClick={handleClick}
    >
      {/* Text */}
      <Text as="p" size="xxs" className="text-white font-medium">
        {text}
      </Text>

      {/* Chevron or Value */}
      <div className="flex items-center">
        {value && typeof value === "string" ? (
          <Text as="p" size="xxs" className="text-right text-gray-200">
            {value.length > 30
              ? value.charAt(0).toUpperCase() + value.slice(1, 30) + "..."
              : value.charAt(0).toUpperCase() + value.slice(1)}
          </Text>
        ) : null}

        <FontAwesomeIcon
          icon={isExpanded ? faAngleDown : faAngleRight}
          fixedWidth
          size="lg"
          className="text-white ml-2"
        />
      </div>

      {/* Optional Description */}
      {description && (
        <Text as="p" size="xxs" className="text-gray-300 whitespace-pre-line mt-2">
          {description}
        </Text>
      )}
    </div>
  );
}

export const EditProfileSettingBubble = ({
  text,
  value,
  maxLength = 1000,
  onSave,
  onBack,
  options,
  description,
  placeholder = "Type here...",
  isLocationField,
}: {
  text: string;
  value?: string | null | undefined;
  maxLength?: number;
  onSave?: (s: string) => Promise<void>;
  onBack?: () => void;
  options?: Array<string>;
  description?: string;
  placeholder?: string;
  isLocationField?: boolean;
}): React.JSX.Element => {
  const [field, setField] = useState<string>(value ?? "");
  const [error, setError] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Loc | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const saveLocation = useSaveLocation({ source: L_Geo_Point_Type.Manual });

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [field, value]);

  useEffect(() => {
    adjustHeight();
  }, []);

  useEffect(() => {
    if (value && value !== field) {
      setField(value);
    }
  }, [value]);

  const handleLocationSelect = async (location: Loc) => {
    setSelectedLocation(location);
    setField(location.label ?? "");
  };

  const handleSave = async () => {
    if (isLocationField && selectedLocation) {
      try {
        await saveLocation({
          lat: selectedLocation.lat,
          long: selectedLocation.long,
        });
      } catch (error) {
        console.error("Error saving location:", error);
        return;
      }
    }

    if (onSave) {
      await onSave(field);
    }
  };

  return (
    <>
      <div className="w-full h-full pb-2 flex items-center" onClick={onBack}>
        <FontAwesomeIcon icon={faAngleLeft} fixedWidth size="2x" color="white" />
        <Text as="p" size="sm" className="text-white font-medium cursor-pointer">
          {text.charAt(text.length - 1) === "?"
            ? ""
            : `Editing ${text.includes(":") ? text.split(":")[0] : text}`}
        </Text>
      </div>
      {isLocationField && (
        <>
          <LocationPicker
            defaultLabel={value ?? ""}
            onSelect={handleLocationSelect}
            className="bg-transparent w-full text-white text-xs outline-none" // Match textarea text size
            placeholder="Search for location..."
            hideIcon={true}
          />
        </>
      )}

      {!isLocationField && options && !(options?.length > 0) && (
        <div className="flex justify-between items-center py-2 my-1 bg-[#464752] rounded-lg px-4">
          <div className="flex flex-1 items-center gap-2">
            <Text as="p" size="xxs" className="whitespace-nowrap">
              {text}
            </Text>

            <textarea
              ref={textareaRef}
              value={field}
              onChange={(e) => {
                if (e.target.value.length <= maxLength) {
                  setError(null);
                  setField(e.target.value);
                } else {
                  setError(`Your ${text} can be up to ${maxLength} characters long.`);
                }
              }}
              placeholder={placeholder}
              className="bg-transparent w-full resize-none overflow-hidden outline-none text-white  h-5"
              rows={text === "Bio:" ? 4 : 1}
            />
          </div>
        </div>
      )}

      {options && options?.length > 0 && (
        <>
          <Text as="p" size="xs" className="whitespace-nowrap py-5">
            {text}
          </Text>
          <div className="flex flex-wrap gap-2 mt-3">
            {options.map((o) => (
              <button
                key={o}
                onClick={() => setField(o)}
                className={clsx(
                  "rounded-full p-2 text-xs text-white focus:outline-none",
                  field === o ? "bg-[#FF625F]" : "bg-[#464752]"
                )}
              >
                {o}
              </button>
            ))}
          </div>
        </>
      )}

      <Text as="p" size="xxs" className="!text-gray-500 mt-2">
        {description}
      </Text>

      <Button
        onClick={handleSave}
        className="fixed bottom-[calc(80px+env(safe-area-inset-bottom))] left-1/2 -translate-x-1/2 !w-4/5"
        variant="standard"
      >
        Save
      </Button>

      {error && (
        <Text as="p" size="xxs" className="!text-red-500 mt-2">
          {error}
        </Text>
      )}
    </>
  );
};

type Prompt = ProfilePromptsQuery["profile_prompts"][0];
type SinglePromptProps = {
  prompt: Prompt;
  //   setLoading: (b: boolean) => void;
  onSave: (s: string) => Promise<void>;
  //   loading: boolean;
};

function SinglePromptSelectOne({
  choices,
  prompt,
  onSave,
}: {
  choices: Array<string>;
  prompt: any; // Prompt type, adjust if necessary
  onSave: (value: string) => Promise<void>;
}): React.JSX.Element {
  const selected = prompt.answers?.[0]?.value || ""; // Get the selected answer or empty string if none

  return (
    <EditProfileSettingBubble
      text={prompt.question_title?.en_US ?? "Choose an option"}
      value={selected}
      options={choices}
      onSave={onSave}
    />
  );
}

function SinglePromptSelectMany({
  choices,
  prompt,
  onSave,
}: {
  choices: Array<string>;
  prompt: any; // Prompt type, adjust if necessary
  onSave: (value: string) => Promise<void>;
}): React.JSX.Element {
  const selected = prompt.answers?.map((ans: any) => ans.value) || []; // Get the selected answers

  const handleSave = (value: string) => {
    // Update the selected values when the user interacts with the buttons
    const updatedSelected = selected.includes(value)
      ? selected.filter((v: string) => v !== value) // Remove from selection if already selected
      : [...selected, value]; // Add to selection if not already selected

    onSave(updatedSelected.join(", ")); // Pass the updated values to onSave
  };

  return (
    <div>
      <Text as="p" size="xxs">
        {prompt.question_title?.en_US ?? "Choose options"}
      </Text>
      <div className="flex flex-wrap gap-2 mt-3">
        {choices.map((choice) => (
          <button
            key={choice}
            onClick={() => handleSave(choice)}
            className={clsx(
              "rounded-full p-2 text-xs text-white focus:outline-none",
              selected.includes(choice)
                ? "bg-[#FF625F]" // Highlight selected options
                : "bg-[#464752]" // Default style for unselected options
            )}
          >
            {choice}
          </button>
        ))}
      </div>
    </div>
  );
}

function SinglePromptText({ onSave, prompt }: SinglePromptProps): React.JSX.Element {
  return (
    <ValidatedInput
      {...{
        hasBadge: false,
        defaultValue: prompt.answers?.[0]?.value ?? "",
        defaultValueTreatAsValid: true,
        submitHandler: onSave,
        onValid: onSave,
        //   className={`input input-bordered ${
        //     errors[prompt.id] ? "input-error" : ""
        //   }`}
        // validationFunction: async ({}) => null,
        fieldSchema: z
          .string()
          .min(3, { message: "Please use at least 3 characters." })
          .max(255, { message: "Please use less than 255 characters" })
          .regex(/^[a-z0-9][-A-Za-z0-9_]*[a-z0-9]$/i, {
            message: "Please use only letters, numbers, underscores, or dashes.",
          }),
      }}
    />
  );
}

function SinglePrompt({
  prompt,
}: {
  prompt: any; // Prompt type, adjust as needed
}): React.JSX.Element {
  const choices = prompt.question_answer_choices?.en_US?.split("|") || []; // Parse choices from the prompt

  const onSave = async (value: string) => {
    // Handle saving the selected value(s)
    console.log("Saved value:", value);
    // Add your mutation or API call here
  };

  return (
    <div className="form-control">
      {choices.length > 0 ? (
        prompt.answer_choices_allow_multiple ? (
          <SinglePromptSelectMany choices={choices} prompt={prompt} onSave={onSave} />
        ) : (
          <SinglePromptSelectOne choices={choices} prompt={prompt} onSave={onSave} />
        )
      ) : (
        <SinglePromptText onSave={onSave} prompt={prompt} />
      )}

      {prompt.question_subtitle?.en_US && (
        <label className="label">
          <span className="label-text-alt">{prompt.question_subtitle?.en_US}</span>
        </label>
      )}
    </div>
  );
}

interface SingleLocation {
  label: string;
  place_id: string;
  lat?: number;
  long?: number;
}

// interface EditFieldProps {
//   field: string;
//   value: string;
//   handleUpdate: (value: string) => void;
//   description?: string;
//   options?: string[];
// }

export function LocationSettingsSection({
  handleClick,
}: {
  handleClick: ({ field, value, handleUpdate, description }: EditFieldProps) => void;
}): React.JSX.Element {
  const user_id = useMustBeLoggedIn() ?? "";
  const { data: locationsData, loading: locationsLoading } = useUserLocations500Query({
    variables: { user_id },
  });
  const saveLocation = useSaveLocation({ source: L_Geo_Point_Type.Manual });
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateLocation = async (location: Loc) => {
    try {
      await saveLocation({
        lat: location.lat,
        long: location.long,
      });
    } catch (error) {
      console.error("Error updating location:", error);
    }
  };

  if (locationsLoading) {
    return <SpinnerFullScreen />;
  }

  const location = locationsData?.users_by_pk?.location_manual?.description ?? "No location set";

  return (
    <>
      <Text as="h3" size="xs" weight="semibold" className="pt-2">
        Location Settings
      </Text>
      <ProfileSettingBubble
        text="Locations:"
        value={location}
        handleClick={() => {
          console.log("Location clicked");
          handleClick({
            field: "Location:",
            value: location,
            handleUpdate: async (value: string) => {
              console.log("Location update requested:", value);
            },
            description: "Update your location",
            isLocationField: true,
          });
        }}
      />
    </>
  );
}
