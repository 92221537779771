import { ReportType, useBlockUserById } from "../screens/user-page/settings/safety";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { PortalDialog } from "shared-web-react/dist/widgets/confirm-provider";
import React from "react";
import { SpinnerButton } from "./spinner-button";
import { VouchControl } from "../screens/user-page/vouching/vouch-controls";
import { allRoutes } from "../util/routes";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { useMySlug } from "../screens/bio/bio-settings";
import {
  GqlOps,
  useHideDiscoveryResult300Mutation,
  useUserIdFromSlugQuery,
} from "shared/dist/__generated__/components";
import { createPortal } from "react-dom";
import { Button } from "../components/atoms/Button";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";

export type EllipsisActionsProps = {
  reportData?: null | { reportType: ReportType; reportItemId: string; owner_slug?: string };
  className?: string;
  slug?: string;
  skip?: boolean;
  forProfile?: boolean;
  isConnected?: boolean;
  disconnectHandler?: () => void;
};

/**
 * EllipsisActions Component
 * @param {EllipsisActionsProps} props
 * @param {ReportType} props.reportType if the actions should make some kind of report
 * @param {string} props.slug if the actions should include blocking a user
 * @param {boolean} props.skip allow this to be "noop'ed" out so we can disable it programmatically, i.e. for chat bubbles "from me"
 * @param {null | React.ReactNode} props.children use other content for the ellipsis instead of the 3 dots
 * @param {boolean} props.forProfile - Determines if the actions are related to a user profile.
 * @param {boolean} props.isConnected - Indicates if the user is connected to the current user.
 * @param {function} props.disconnectHandler - Handler function for disconnecting the user.
 * @returns
 */
export function EllipsisActions({
  slug,
  reportData,
  children,
  className,
  skip,
}: React.PropsWithChildren<EllipsisActionsProps>): React.JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);
  const mySlug = useMySlug()?.slug;
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const addToast = useAddToast();

  const [hideMutation] = useHideDiscoveryResult300Mutation();
  const hideUser = React.useCallback(async () => {
    const result = await hideMutation({
      refetchQueries: [
        GqlOps.Query.HiddenDiscoveryUsers431,
        GqlOps.Query.DiscoveryResults300,
        GqlOps.Query.NewsFeed440,
      ],
      variables: { unhide: false, slug: reportData?.owner_slug ?? "" } /* cSpell:disable-line */,
    });
    if (result?.errors) {
      addToast({
        content: "Error reaching our server, please try again",
        color: "warning",
      });
      return;
    }
  }, [hideMutation]);
  React.useEffect(() => {
    if (!isOpen) return;

    const handleClick = (e: MouseEvent) => {
      if (buttonRef.current?.contains(e.target as Node)) return;
      setIsOpen(false);
    };

    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [isOpen]);

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        className={clsx(
          "EllipsisActions",
          className,
          skip && "SkippedEllipsisActions",
          children && "text-left"
        )}
        onClick={(e) => {
          if (skip) return;
          setIsOpen(!isOpen);
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {children || <FontAwesomeIcon icon={faEllipsis} fixedWidth />}
      </button>

      {isOpen && (
        <Portal>
          <div
            className="fixed inset-0 flex items-center justify-center z-[100]"
            style={{ background: "rgba(42, 44, 56, 0.7)" }} // Updated to use #2A2C38 for the overlay background
          >
            <div
              className="w-[320px] bg-[#2A2C38] rounded-xl p-10 z-[101] 
                         flex flex-col gap-3 shadow-2xl"
            >
              {/* Vouch Button */}
              {/* {slug && slug !== mySlug && (
                <Button
                  className="w-full h-[56px] rounded-2xl bg-[#F3E8FF] 
                             text-purple-900 font-semibold"
                  onClick={() => setIsOpen(false)}
                  variant="outline"
                >
                  ♥ Vouch For {slug}
                </Button>
              )} */}
              {reportData && (
                <Button
                  onClick={async () => {
                    await hideUser();
                    setIsOpen(false);
                  }}
                >
                  Hide User
                </Button>
              )}

              {/* Report Button */}
              {reportData && (
                <Button
                  variant="standard"
                  onClick={() =>
                    navigate(
                      allRoutes.REPORT_MEDIA.buildPath({
                        reportType: reportData.reportType,
                        media_upload_id: reportData.reportItemId,
                      })
                    )
                  }
                >
                  Report Content
                </Button>
              )}

              {/* Block Button */}
              {/* {slug && slug !== mySlug && (
                <Button
                  className="w-full h-[56px] rounded-2xl bg-[#FCD34D]
                             text-black font-semibold"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Block @{slug}
                </Button>
              )} */}

              {/* Cancel Button */}
              <Button onClick={() => setIsOpen(false)} variant="outline">
                Cancel
              </Button>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
}

// Helper Portal component to render outside current DOM hierarchy
function Portal({ children }: { children: React.ReactNode }) {
  const [mounted, setMounted] = React.useState(false);
  const portalRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    setMounted(true);
    const portalContainer = document.createElement("div");
    portalContainer.className = "portal-container";
    document.body.appendChild(portalContainer);
    portalRef.current = portalContainer;

    return () => {
      if (portalContainer.parentElement) {
        portalContainer.parentElement.removeChild(portalContainer);
      }
    };
  }, []);

  if (!mounted || !portalRef.current) return null;

  return createPortal(children, portalRef.current);
}
