import React from "react";

import simmrLogo from "../../../../assets/simmr-logo-red.png";

const PublicEventsRoot: React.FC = () => {
  return (
    <div className="min-h-full px-6 w-screen bg-[#2A2C38] text-white">
      <div className="flex items-center justify-center gap-2 py-4 mx-8">
        <img className="w-32" src={simmrLogo} alt="Simmr logo" />
        <h1 className="text-3xl tracking-widest font-thin">EVENTS</h1>
      </div>
      <h3 className="px-14 text-md text-center">
        Want to connect with open-minded ENM people in the wild? 😈
      </h3>
    </div>
  );
};

export default PublicEventsRoot;
