import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import {
  Media_Upload_Statuses_Enum,
  useProcessImageInDevMutation,
  useUploadMediaMutation,
  useUploadStatusLazyQuery,
} from "shared/dist/__generated__/components";
import { PreviewComponentProps, getUploadPreviewForBatchItemsMethod } from "@rpldy/upload-preview";
import Uploady, {
  useAbortAll,
  useBatchAddListener,
  useBatchFinalizeListener,
  useItemProgressListener,
  useRequestPreSend,
  useUploady,
} from "@rpldy/uploady";
import { faSpinner, faUpload } from "@fortawesome/pro-solid-svg-icons";

import React from "react";
import { asUploadButton } from "@rpldy/upload-button";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useIsDev } from "shared/dist/util/env";

export type MediaOptions = {
  is_private?: boolean;
  is_nsfw?: boolean;
  exclude_from_bio?: boolean;
  exclude_from_feed?: boolean;
};

type UploaderCommonProps = {
  btnContent?: string | React.JSX.Element | null;
  btnClassName?: React.HTMLAttributes<HTMLDivElement>["className"];
  iconClassName?: React.HTMLAttributes<HTMLDivElement>["className"];
  iconOverride?: FontAwesomeIconProps["icon"];
  showPreviewConfirmation?: boolean;
  disallowMultiple?: boolean;
};

export type ImageUploaderProps = {
  onStart?: () => void;
  onDismiss?: () => void;
  onError: () => void;
  onComplete: (s: string) => void;
  onUploadIdReceived?: (s: string) => void;
  mediaOptions?: MediaOptions;
} & UploaderCommonProps;

type UploadButtonProps = {
  progress?: number; // 0-100
  processing?: boolean;
  loading?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
  UploaderCommonProps;
const UploadButton = asUploadButton(
  React.forwardRef<HTMLButtonElement>(
    (
      {
        processing,
        btnClassName,
        iconClassName,
        iconOverride,
        loading,
        btnContent,
        progress,
        showPreviewConfirmation,
        ...props
      }: UploadButtonProps,
      ref
    ) => (
      <button
        {...props}
        ref={ref}
        type="button"
        style={{ cursor: "pointer" }}
        disabled={processing || loading}
        className={classNames("btn btn-primary", btnClassName)}
      >
        {/* <span className="w-8" /> */}
        <span className="inline-block relative">
          <div className="absolute w-full h-full justify-center items-center flex">
            <FontAwesomeIcon
              className={classNames(loading ? "hidden" : "", iconClassName)}
              spin={processing}
              icon={processing ? faSpinner : (iconOverride ?? faUpload)}
              fixedWidth
            />
          </div>
          <div className="flex h-full w-full justify-center items-center">
            <div
              className={classNames(
                // "mr-2 ",
                loading ? "opacity-100" : "opacity-0",
                "radial-progress "
              )}
              style={
                {
                  "--value": progress,
                  "--size": "1.5rem",
                  "--thickness": "4px",
                } as any
              }
            />
          </div>
        </span>
        {btnContent ?? "Upload Files"}
      </button>
    )
  )
);

function ImageUploadButton({
  btnClassName,
  btnContent,
  iconClassName,
  iconOverride,
  loading,
  mediaOptions,
  onError,
  onFinalize,
  onStart,
  processing,
  setUploadId,
  showPreviewConfirmation,
}: {
  loading: boolean;
  onFinalize: () => void;
  onError: () => void;
  setUploadId: (s: string) => void;
  onStart: () => void;
  mediaOptions?: MediaOptions;
  processing?: boolean;
} & UploaderCommonProps): React.JSX.Element {
  const progressData = useItemProgressListener();
  const progressVal = progressData?.completed ?? 0;
  const [uploadMedia] = useUploadMediaMutation();
  useBatchFinalizeListener(async (batch) => {
    // console.log("🚀 ~ file: images.tsx ~ line 14 ~ useBatchFinalizeListener ~ batch", batch);
    onFinalize();
  });
  useRequestPreSend(async ({ items, options }) => {
    if (items.length !== 1) {
      return false;
    }
    items[0].file.type;
    // console.log("🚀 ~ file: images.tsx ~ line 10 ~ useRequestPreSend ~ items", items);
    try {
      onStart();
      const signedUrl = await uploadMedia({
        variables: {
          content_type: items[0].file.type,
          ...mediaOptions,
        },
      });
      const url = signedUrl?.data?.lm_upload_media?.signed_url_data.presigned_url;
      // console.log("🚀 ~ file: images.tsx ~ line 23 ~ useRequestPreSend ~ url", url);
      if (!signedUrl?.data?.lm_upload_media || !url) {
        onError();
        return false;
      }
      setUploadId(signedUrl?.data?.lm_upload_media?.media_upload_id!);
      return {
        options: {
          destination: { url, method: "PUT" },
          sendWithFormData: false,
        },
      };
    } catch (err) {
      console.error("Error uploading: ", err);
      onError();
      return false;
    }
  });
  return (
    <UploadButton
      extraProps={{
        btnClassName,
        iconClassName,
        iconOverride,
        btnContent,
        className: "ImageUploadButton btn btn-primary",
        // loading: true,
        // progress: 50,
        loading,
        progress: progressVal,
        processing,
        showPreviewConfirmation,
      }}
    />
  );
}

// url?: string;
// filesParamName?: string;
// params?: Record<string, unknown>;
// headers?: Record<string, unknown>;
// method?: string;

function UploadStatus(): React.JSX.Element {
  const progressData = useItemProgressListener();
  // // console.log(
  // //   "🚀 ~ file: images.tsx ~ line 65 ~ UploadStatus ~ progressData",
  // //   progressData
  // // );
  const val = progressData?.completed ?? 0;
  return (
    <div className="radial-progress mx-4" style={{ "--value": val } as any}>
      {`${Math.floor(val)}%`}
    </div>
  );
}

export function ImageUploader({
  btnClassName,
  btnContent,
  iconClassName,
  iconOverride,
  mediaOptions,
  showPreviewConfirmation,
  onComplete,
  onDismiss,
  onError,
  onStart,
  onUploadIdReceived,
  disallowMultiple,
}: ImageUploaderProps): React.JSX.Element {
  const [uploadId, setUploadId] = React.useState<null | string>(null);
  const [loadingAny, setLoadingAny] = React.useState(false);
  const [finishedUploading, setFinishedUploading] = React.useState(false);
  const addToast = useAddToast();
  const [devForceProcess] = useProcessImageInDevMutation();
  const [getUploadStatus, { data, loading: uploadStatusLoading, stopPolling }] =
    useUploadStatusLazyQuery();
  const isDev = useIsDev();
  React.useEffect(() => {
    // console.log("🚀 ~ file: images.tsx ~ line 110 ~ status", data?.media_uploads_by_pk?.status);
  }, [data]);
  React.useEffect(() => {
    if (!uploadId) {
      return;
    }
    if (!finishedUploading) {
      return;
    }
    devForceProcess({ variables: { media_upload_id: uploadId } });
    getUploadStatus({
      pollInterval: 1000,
      variables: { media_upload_id: uploadId },
      // onCompleted: (data) => {
      // //   console.log("🚀 ~ file: images.tsx ~ line 114 ~ React.useEffect ~ data", data);
      //   if (data.media_uploads_by_pk?.status === Media_Upload_Statuses_Enum.V1Processed) {
      //     stopPolling();
      //     setFinishedUploading(false);
      //     setLoadingAny(false);
      //     setUploadId(null);
      //     onComplete(uploadId);
      //     onDismiss?.();
      //   }
      // },
    });
    const errorTimeout = setTimeout(() => {
      addToast({
        content: "Failed to upload image, please try again.",
        color: "error",
      });
      setFinishedUploading(false);
      setLoadingAny(false);
      setUploadId(null);
      onComplete(uploadId);
      onDismiss?.();
    }, 1000 * 60);
    return () => {
      stopPolling();
      errorTimeout && clearTimeout(errorTimeout);
    };
  }, [finishedUploading, uploadId, stopPolling]);
  React.useEffect(() => {
    if (
      uploadId &&
      (data?.media_uploads_by_pk?.status === Media_Upload_Statuses_Enum.V2Processed ||
        data?.media_uploads_by_pk?.status === Media_Upload_Statuses_Enum.V1Processed)
    ) {
      stopPolling();
      setFinishedUploading(false);
      setLoadingAny(false);
      setUploadId(null);
      onComplete(uploadId);
      onDismiss?.();
    }
  }, [
    uploadId,
    data?.media_uploads_by_pk?.status,
    stopPolling,
    setFinishedUploading,
    setLoadingAny,
    setUploadId,
    onComplete,
    onDismiss,
  ]);
  return (
    <React.Fragment>
      {onDismiss && (
        <button
          className="btn btn-sm btn-circle absolute right-2 top-2"
          disabled={loadingAny}
          onClick={onDismiss}
        >
          x
        </button>
      )}
      {/* {loadingAny ? ( <SpinnerFullScreen />) : ( */}
      <React.Fragment>
        <Uploady
          fileFilter={(file) => typeof file !== "string" && "size" in file && file.size <= 10e6}
          autoUpload={!showPreviewConfirmation}
          clearPendingOnAdd
          multiple={!disallowMultiple && !showPreviewConfirmation}
          accept=".png,.jpg,.jpeg,.heic,.heif"
        >
          <ImageUploadButton
            {...{
              btnClassName,
              iconClassName,
              iconOverride,
              btnContent,
              showPreviewConfirmation,
            }}
            loading={loadingAny}
            processing={finishedUploading}
            mediaOptions={mediaOptions}
            onStart={() => {
              setLoadingAny(true);
              onStart?.();
            }}
            onError={onError}
            setUploadId={(id) => {
              setUploadId(id);
              onUploadIdReceived?.(id);
            }}
            onFinalize={() => {
              setFinishedUploading(true);
              // onDismiss();
              setLoadingAny(false);
            }}
          />
          {showPreviewConfirmation ? <UploadConfirmation /> : <></>}
        </Uploady>
      </React.Fragment>
    </React.Fragment>
  );
}

function PreviewComponent({
  url,
  ...props
}: Pick<ImageUploaderProps, "onDismiss"> & PreviewComponentProps): React.JSX.Element {
  const { processPending, clearPending } = useUploady();
  const [processedUrls, setProcessedUrls] = React.useState<Array<string>>([]);
  const abort = useAbortAll();
  const confirmOpts = {
    title: "Upload this image?",
    content: (
      <div className={clsx("flex items-center justify-center")}>
        <img className={clsx("max-h-32 max-w-32")} src={url} alt="" />
      </div>
    ),
    okButtonContent: "Upload",
    onOk: async () => processPending(),
    showDelay: 100,
    cancelButtonContent: "Cancel",
    onCancel: async () => {
      clearPending(), props.onDismiss?.(), abort();
    },
  };
  console.log("🚀 ~ Outside of useEffect: file: images.tsx:364 ~ confirmOpts:", confirmOpts?.title);

  const confirm = useConfirm();
  React.useEffect(() => {
    if (processedUrls.includes(url ?? "")) {
      return;
    }
    confirm(url ? confirmOpts : null);
    setProcessedUrls((processedUrls) => processedUrls.concat(url ?? ""));
  }, [processedUrls, setProcessedUrls, url, confirm, confirmOpts]);
  return <></>;
}

const UploadPreviewOnAdd = getUploadPreviewForBatchItemsMethod(useBatchAddListener);

function UploadConfirmation({
  onDismiss,
}: Pick<ImageUploaderProps, "onDismiss">): React.JSX.Element {
  return (
    <UploadPreviewOnAdd PreviewComponent={PreviewComponent} previewComponentProps={{ onDismiss }} />
  );
}
