import {
  Feature_Flags_Enum,
  L_News_Feed_Type,
  useNewsFeed440Query,
} from "shared/dist/__generated__/components";
import { H2, H3 } from "shared-web-react/dist/widgets/text";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { classNames, sortBy } from "shared/dist/util";
import { useIsDev, useIsProd } from "shared/dist/util/env";

import { Container } from "../../widgets/container";
import { DateTime } from "luxon";
import { ErrorBoundary } from "react-error-boundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NewsFeedItem } from "./news-feed/items";
import React from "react";
import { VisibilityObserver } from "shared-web-react/dist/widgets/visibility-observer";
import clsx from "clsx";
import { faHome } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useOnScreenLogger } from "shared-web-react/dist/util/on-screen-logger";
import { useVirtualizer } from "@tanstack/react-virtual";
import { SimmerZeroScreen } from "../../widgets/zero-screens";
import { Button } from "../../components/atoms/Button";
import { allRoutes } from "../../util/routes";
import { useNavigate } from "react-router-dom";

export function Home(): React.JSX.Element {
  const [useDummyData, setUseDummyData] = React.useState(false);
  const [feedType, setFeedType] = React.useState<L_News_Feed_Type>(L_News_Feed_Type.DiscoveryOnly);
  const { loading, data, fetchMore } = useNewsFeed440Query({
    variables: { filter: { feed_type: feedType }, dummy_data: useDummyData },
    fetchPolicy: "cache-first",
  });

  const navigate = useNavigate();
  const isProd = useIsProd();
  const isDev = useIsDev();
  const newsFeedResult = data?.l_news_feed;
  const sorted = React.useMemo(
    () =>
      newsFeedResult?.__typename === "L_News_Feed_Response_Success"
        ? (newsFeedResult.items ?? [])
        : [],
    [newsFeedResult]
  );

  // Fetch more when reaching the bottom
  const onAtBottom = React.useCallback(
    async (atBottom: boolean) => {
      if (!atBottom || loading) return;
      const nextPage = match(newsFeedResult)
        .with({ __typename: "L_News_Feed_Response_Success" }, (data) => data.next_page_cursor)
        .otherwise(() => null);
      if (nextPage) fetchMore({ variables: { page: nextPage, cursor: nextPage } });
    },
    [loading, fetchMore, newsFeedResult]
  );

  const scrollParentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: sorted.length + 1,
    getScrollElement: () => scrollParentRef.current,
    estimateSize: () => 750,
    getItemKey: (index) => sorted[index]?.id ?? "<empty>",
    measureElement: (element) => element.children[0]?.getBoundingClientRect?.().height ?? 0,
    gap: 8,
    overscan: 8,
  });

  React.useEffect(() => {
    if (!sorted?.length) return;
    const idToScrollTo = window.history.state?.newsFeedItemId;
    if (!idToScrollTo) return;
    const index = sorted.findIndex((i) => i.id === idToScrollTo);
    if (index !== -1) {
      setTimeout(() => {
        rowVirtualizer.scrollToIndex(index);
        window.history.replaceState({}, "");
      }, 1);
    }
  }, [sorted]);

  if (loading && !newsFeedResult) return <SpinnerCentered />;
  if (newsFeedResult?.__typename === "L_Simple_Response_Error")
    return <div>err: {newsFeedResult.human_readable_error}</div>;
  if (newsFeedResult?.__typename === "L_News_Feed_Bad_Cursor")
    return <div>err: invalid cursor</div>;
  if (!sorted.length)
    return (
      <EmptyState
        isProd={Boolean(isProd)}
        setUseDummyData={setUseDummyData}
        navigate={navigate}
        setFeedType={setFeedType}
        feedType={feedType}
      />
    );

  return (
    <div className="absolute inset-0 max-h-full overflow-y-hidden min-h-0 pb-10">
      <div className="px-4">
        <FeedTypeSwitcher feedType={feedType} setFeedType={setFeedType} />
      </div>
      <div ref={scrollParentRef} className="relative h-full max-h-full min-h-0 overflow-y-auto">
        <Container size="xs" className="h-full flex flex-col items-center pb-16 text-white">
          <div
            className="w-full max-w-full space-y-3 pb-16"
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: "100%",
              position: "relative",
            }}
          >
            <ErrorBoundary FallbackComponent={FeedFallback}>
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const item = sorted[virtualRow.index];
                return (
                  <div
                    key={virtualRow.key}
                    data-index={virtualRow.index}
                    ref={rowVirtualizer.measureElement}
                    style={{
                      position: "absolute",
                      top: 0,
                      width: "100%",
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${Math.max(virtualRow.start)}px)`,
                    }}
                  >
                    {item && (
                      <>
                        <NewsFeedItem disableDeepLinks={useDummyData} item={item} />
                        <VisibilityObserver
                          onVisibility={(b) =>
                            b && window.history.replaceState({ newsFeedItemId: item.id }, "")
                          }
                        />
                      </>
                    )}
                    {virtualRow.index === sorted.length - 1 && (
                      <VisibilityObserver
                        onVisibility={onAtBottom}
                        className="h-1 pb-16 mx-auto col-span-3 w-1"
                      />
                    )}
                  </div>
                );
              })}
            </ErrorBoundary>
          </div>
        </Container>
      </div>
    </div>
  );
}

function FeedFallback(): React.JSX.Element {
  return (
    <div className="w-full h-full flex-col-center-center bg-primary">
      <Spinner />
    </div>
  );
}

function EmptyState({
  isProd,
  setUseDummyData,
  navigate,
  feedType,
  setFeedType,
}: {
  isProd: boolean;
  setUseDummyData: (val: boolean) => void;
  navigate: (path: string) => void;
  feedType: L_News_Feed_Type;
  setFeedType: (type: L_News_Feed_Type) => void;
}) {
  return (
    <Container className="flex flex-col gap-2 bg-[#2A2C38] h-full  pb-10">
      <FeedTypeSwitcher feedType={feedType} setFeedType={setFeedType} />
      <SimmerZeroScreen
        header="No connections yet"
        text="Don't worry, you're just getting started! Discover people to connect with and expand your network."
        btnText={isProd ? "Find Connections" : "Dev User: Use dummy data"}
        clickHandler={() =>
          isProd ? navigate(allRoutes.DISCOVERY.buildPath({})) : setUseDummyData(true)
        }
      />
    </Container>
  );
}

function FeedTypeSwitcher({
  feedType,
  setFeedType,
}: {
  feedType: L_News_Feed_Type;
  setFeedType: (type: L_News_Feed_Type) => void;
}) {
  const feedTypes = [
    { e: L_News_Feed_Type.DiscoveryOnly, t: "Discover" },
    { e: L_News_Feed_Type.ConnectionsOnly, t: "Connections" },
    { e: L_News_Feed_Type.StatusOnly, t: "Statuses" },
  ];

  return (
    <Container size="xs" className="mb-1 mt-4">
      <div className={clsx("flex flex-row justify-between gap-4")}>
        {feedTypes.map((item) => (
          <button
            key={item.e}
            onClick={() => setFeedType(item.e)}
            className={clsx(
              "text-sm px-2 py-2 w-1/3 rounded-xl transition-all flex-1 text-center",
              feedType === item.e ? "bg-[#FF424D] text-white" : "bg-[#696969]/40 text-white"
            )}
          >
            {item.t}
          </button>
        ))}
      </div>
    </Container>
  );
}
