import {
  Feature_Flags_Enum,
  L_News_Feed_Item,
  L_News_Feed_Item_Media_Reaction,
  L_News_Feed_Item_Mention,
  L_News_Feed_Item_Message_Reaction,
  useBioSummaryAbbreviatedLazyQuery,
  useInboundRelationshipRequestsForUserV410Query,
  useNotificationsFeed403Query,
} from "shared/dist/__generated__/components";
import { P, match } from "ts-pattern";
import { useEffect, useMemo, useRef } from "react";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Container } from "../../widgets/container";
import { DateTime } from "luxon";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H4 } from "shared-web-react/dist/widgets/text";
import { Link } from "react-router-dom";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { VerificationBox } from "../../widgets/verification-box";
import { ZeroScreen } from "../../widgets/zero-screens";
import { allRoutes } from "../../util/routes";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { sortBy } from "shared/dist/util";
import { useIndicators } from "../../widgets/indicators";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useMyId } from "shared/dist/auth-data";
import { useSecureStorage } from "../../widgets/use-secure-storage";
import { useTypedParams } from "react-router-typesafe-routes/dom";

type NotificationsProps = {
  bio?: string | null | undefined;
  owner_slug?: string | null | undefined;
  slug?: string | null | undefined;
  screen_name?: string | null | undefined;
  status_update?: string | null | undefined;
  showNewBadge?: boolean;
  id: string;
  created_at: string;
  __typename: string;
};

export default function Notifications(): React.JSX.Element {
  const { slug } = useTypedParams(allRoutes.PROFILE);
  const my_id = useMyId();
  const { clearNotificationIndicator } = useIndicators();
  const [isLoading, setIsLoading] = React.useState(true);
  const { lastChecked: notificationsLastChecked } = useSecureStorage("notifications");
  if (!my_id) return <SpinnerCentered />;
  const { data: relationshipData, loading: relationshipDataLoading } =
    useInboundRelationshipRequestsForUserV410Query({
      variables: { types: ["friend", "like"], my_id },
    });
  const { loading: feedDataLoading, data: feedData } = useNotificationsFeed403Query({
    variables: { dummy_data: false },
  });

  useEffect(() => {
    if (!relationshipDataLoading && !feedDataLoading) {
      setIsLoading(false);
      clearNotificationIndicator();
    }
  }, [relationshipDataLoading, feedDataLoading, clearNotificationIndicator]);

  if (!my_id || !slug) return <SpinnerCentered />;

  const relationshipProfiles = useMemo(() => {
    return (
      relationshipData?.relationships_union.map(
        (r) =>
          ({
            ...r.snd_user_summary,
            created_at: r?.created_at,
            __typename: r?.type,
          }) as NotificationsProps
      ) ?? []
    );
  }, [relationshipData]);

  const newsFeedResult = feedData?.l_notifications_feed;
  const newsFeedResultItems =
    newsFeedResult && "items" in newsFeedResult ? newsFeedResult.items : [];

  const { newNotifications, oldNotifications, sortedData } = useMemo(() => {
    const oneWeekAgo = DateTime.now().minus({ weeks: 1 });
    const sortedData = sortBy([...relationshipProfiles, ...newsFeedResultItems], (item) => {
      return DateTime.fromISO(item?.created_at ?? "1970-01-01T00:00:00.000Z").toMillis();
    })
      .filter((n) => {
        if ("reactor_slug" in n) {
          return n.reactor_slug !== slug;
        }
        if ("slug" in n) {
          return n.slug !== slug;
        }
      })
      .reverse()
      .map((n: NotificationsProps) => ({
        ...n,
        showNewBadge:
          notificationsLastChecked && n.created_at
            ? n.created_at > notificationsLastChecked
            : false,
      }));

    return {
      newNotifications: sortedData.filter(
        (n: NotificationsProps) => DateTime.fromISO(n.created_at) >= oneWeekAgo
      ),
      oldNotifications: sortedData.filter(
        (n: NotificationsProps) => DateTime.fromISO(n.created_at) < oneWeekAgo
      ),
      sortedData,
    };
  }, [feedData, relationshipData, notificationsLastChecked, slug]);

  const showNotifications = useIsFeatureEnabled(Feature_Flags_Enum.NotificationsInAppV00);

  if (!showNotifications)
    return (
      <Container className="bg-[#2A2C38] flex justify-center h-full w-full">
        <MockNotifications slug={slug} />
      </Container>
    );

  if (!slug || !my_id)
    return <ErrorComp caption={`404: We're sorry, but can't find @${slug}'s profile...`} />;

  if (isLoading) return <SpinnerCentered />;

  return (
    <div className="flex justify-center h-full w-full px-6 text-white overflow-auto">
      <div className="md:w-full lg:mx-w-2/3 flex flex-col min-h-full">
        {/* <div className="mt-2 my-5"> */}
        <VerificationBox
          heading="🎉 Quick Verification, Endless Fun! 🎉"
          text="Verification is simple and fast, giving you full access to all the awesome features Simmr offers. Tap here to verify now!"
        />
        {/* </div> */}

        <div className="flex-1 overflow-auto">
          <H4 className="my-2">This Week</H4>
          {newNotifications?.length > 0 ? (
            newNotifications.map((notification, index) => (
              <NotificationContainer
                key={notification.id || index.toString()}
                my_slug={slug}
                notification={notification}
              />
            ))
          ) : (
            <p className="my-5 w-full">No New Notifications</p>
          )}

          <H4 className="my-2">Older</H4>
          {oldNotifications?.length > 0 ? (
            oldNotifications.map((notification, index) => (
              <NotificationContainer
                key={notification.id || index.toString()}
                my_slug={slug}
                notification={notification}
              />
            ))
          ) : (
            <p className="my-5 w-full">No Older Notifications</p>
          )}
        </div>
      </div>
    </div>
  );
}

function NotificationContainer({
  my_slug,
  notification,
}: React.PropsWithChildren<{
  my_slug: string;
  notification: L_News_Feed_Item | NotificationsProps;
}>): React.JSX.Element | null {
  if (!notification) return null;
  const reactor_slug = match(notification)
    .with({ reactor_slug: P.select() }, (p) => p)
    .with({ slug: P.select() }, (p) => p)
    .otherwise(() => null);
  const [query, { data, loading }] = useBioSummaryAbbreviatedLazyQuery();
  const screenName = data?.user_summaries?.[0]?.screen_name;
  const { created_at } = notification;

  useEffect(() => {
    reactor_slug && query({ variables: { slug: reactor_slug } });
  }, [reactor_slug]);

  // if ("reactor_slug" in notification) {
  //   reactor_slug = notification.reactor_slug;
  // }
  // if ("slug" in notification) {
  //   reactor_slug = notification?.slug || "";
  // }
  let showNewBadge = false;
  if ("showNewBadge" in notification) {
    showNewBadge = notification.showNewBadge || false;
  }
  if (!reactor_slug) return <></>;

  return (
    <div key={created_at} className="flex items-center justify-between">
      <Link
        to={allRoutes.PROFILE.buildPath({ slug: reactor_slug })}
        className={`cursor-pointer profile-list-avatar flex-0 flex items-center py-2 relative`}
      >
        <div className="relative">
          {showNewBadge && (
            <FontAwesomeIcon
              className="text-warning absolute -top-1 text-sm z-10"
              icon={faSparkles}
            />
          )}
          <Avatar tailwindSize="10" slug={reactor_slug} />
        </div>
      </Link>
      {match(notification["__typename"])
        .with("L_News_Feed_Item_Media_Reaction", () => {
          notification = notification as L_News_Feed_Item_Media_Reaction;
          const { reaction, presigned_url: mediaImage, media_upload_id } = notification;
          return (
            <Link
              to={allRoutes.PROFILE.MEDIA_ITEM.buildPath({ slug: my_slug, media_upload_id })}
              className={`cursor-pointer profile-list-avatar flex-0 flex items-center py-2 w-full`}
            >
              <div className="flex justify-between w-full items-center">
                <p className="text-white min-w-auto text-sm px-2">
                  <strong>{screenName}</strong> reacted to your photo: {reaction}
                </p>
                <div
                  className={`h-16 w-16 lg:w-24 lg:h-24 aspect-square items-center align-middle bg-contain bg-no-repeat bg-center shadow-md`}
                  style={{
                    backgroundImage: `url(${mediaImage})`,
                  }}
                />
              </div>
            </Link>
          );
        })
        .with("L_News_Feed_Item_Status_Reaction", () => {
          notification = notification as L_News_Feed_Item_Message_Reaction;
          const { reaction } = notification;
          return (
            <Link
              to={allRoutes.PROFILE.buildPath({ slug: my_slug })}
              className={`cursor-pointer profile-list-avatar flex-0 flex items-center py-2 w-full`}
            >
              <p className="text-white min-w-auto text-sm px-2">
                <strong>{screenName}</strong> reacted to your status update: {reaction}
              </p>
            </Link>
          );
        })
        .with("L_News_Feed_Item_Message_Reaction", () => {
          notification = notification as L_News_Feed_Item_Message_Reaction;
          const { reaction, thread_id } = notification;
          return (
            <Link
              to={allRoutes.MESSAGING.LIST.THREAD.buildPath({ thread_id })}
              className="flex justify-between w-full"
            >
              <p className="text-white min-w-auto text-sm px-2">
                <strong>{screenName}</strong> reacted to your message: {reaction}
              </p>
              <button className="btn bg-[#FF625F] border-none text-white  btn-sm">View</button>
            </Link>
          );
        })
        .with("like", () => {
          notification = notification as NotificationsProps;
          const { slug, screen_name } = notification;
          if (!slug) return null;

          return (
            <div className="flex justify-between w-full">
              <Link
                to={allRoutes.DISCOVERY.MY_INCOMING_LIKES.buildPath({ slug: my_slug })}
                className={`cursor-pointer profile-list-avatar flex-0 flex items-center  justify-between py-2 w-full`}
              >
                <p className="text-white min-w-auto text-sm px-2">
                  <strong>{screen_name}</strong> liked you on Discovery.
                </p>
                <button className="btn bg-[#FF625F] border-none text-white  btn-sm">View</button>
              </Link>
            </div>
          );
        })
        .with("friend", () => {
          notification = notification as NotificationsProps;
          const { slug, screen_name } = notification;
          if (!slug) return null;

          return (
            <Link
              to={allRoutes.PROFILE.FRIENDS.INBOUND_REQUESTS.buildPath({ slug: my_slug })}
              className={`cursor-pointer profile-list-avatar flex-0 flex items-center justify-between py-2 w-full`}
            >
              <p className="text-white min-w-auto text-sm px-2">
                <strong>{screen_name}</strong> sent you a friend request.
              </p>
              <button className="btn bg-[#FF625F] border-none text-white  btn-sm">View</button>
            </Link>
          );
        })
        .with("L_News_Feed_Item_Mention", () => {
          notification = notification as L_News_Feed_Item_Mention;
          const { owner_slug: slug, thread_id } = notification;
          if (!slug) return null;

          return (
            <Link
              to={allRoutes.MESSAGING.LIST.THREAD.buildPath({ thread_id })}
              className={`cursor-pointer profile-list-avatar flex-0 flex items-center justify-between py-2 w-full`}
            >
              <p className="text-white min-w-auto text-sm px-2">
                <strong>{screenName}</strong> mentioned you in a thread.
              </p>
              <button className="btn bg-[#FF625F] border-none text-white  btn-sm">View</button>
            </Link>
          );
        })
        .otherwise(() => null)}
    </div>
  );
}

function MockNotifications({ slug }: { slug: string }) {
  const notifications: NotificationsProps[] = [
    {
      slug: "Simmrteam",
      showNewBadge: true,
      created_at: new Date().toISOString(),
      id: "sample",
      __typename: "friend",
    },
    {
      slug: "Simmrteam",
      showNewBadge: true,
      created_at: new Date().toISOString(),
      id: "sample2",
      __typename: "like",
    },
  ];

  return (
    <div className="flex flex-col h-full w-full overflow-hidden">
      <ZeroScreen screenType="notifications">
        <Container className="p-6 flex flex-col h-full max-w-md lg:max-w-lg">
          <div>
            <p className="text-white text-xl md:text-[2xl] max-w-md sm:max-w-full ">
              You currently do not have any Notifications. Stay active to see what's new!
            </p>
            <h1 className="text-xl md:text-[2xl] text-white my-5">
              New Notifications will show up just like this
            </h1>
            <div className="md:w-full lg:mx-w-2/3">
              <H4 className="m-2">This Week</H4>
              <NotificationContainer my_slug={slug} notification={notifications[0]} />
              <NotificationContainer my_slug={slug} notification={{ ...notifications[1] }} />
            </div>
          </div>
        </Container>
      </ZeroScreen>
    </div>
  );
}
