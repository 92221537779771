import { ContentBox } from "../../widgets/content-box";
import {
  GqlOps,
  useEnterEventCodeMutation,
  useEventSummaryByIdQuery,
  useEventSummaryBySlug553Query,
  useEventDetailsBySlug553Query,
  useJoinPublicEvent553Mutation,
  // useRemoveUserFromEvent557Mutation,
  useLeavePublicEventMutation,
  type EventDetails553Query,
  Onboarding_Statuses_Enum,
  useRemoveUserFromEvent557Mutation,
} from "shared/dist/__generated__/components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ProfileList, profileListItemFromSummary } from "../../widgets/profile-list";
import { Spinner, SpinnerCentered, SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";
import { allRoutes, useMustBeLoggedIn } from "../../util/routes";
import { classNames, filterNulls } from "shared/dist/util";
import { faCheck, faLock, faX } from "@fortawesome/pro-solid-svg-icons";
import { faGear, faHeart, faPaperPlane, faLocationDot } from "@fortawesome/pro-regular-svg-icons";

import { faCalendarDays } from "@fortawesome/sharp-solid-svg-icons";
import { useEnv, useMkUrl } from "shared/dist/util/env";
import clsx from "clsx";

import { DateTime } from "luxon";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputLeftAddon } from "../../widgets/input";
import Linkify from "linkify-react";
import React from "react";
import { SpinnerButton } from "../../widgets/spinner-button";
import { UserPageContainer } from "../user-page/common";
import { VerificationBox } from "../../widgets/verification-box";
import { faMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useForm } from "react-hook-form";
import { useIdentityVerifiedStatus } from "../user-page/settings/identity/common";
import { useMyId } from "shared/dist/auth-data";
import { useNavigateToLogin } from "../../util/redirects";
import { useSetPageTitle } from "shared-web-react/dist/widgets/nav-stack-view";
import { useShare } from "../../widgets/share";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import ChipContainer from "../../widgets/chip-container";
import type { EventTags } from "./events/common";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Button } from "../../components/atoms/Button";
import { match } from "ts-pattern";
import { BigModal } from "../../widgets/bio/big-modal";
import { Text } from "../../components/atoms/Text";
import { useMyInfo } from "../bio/bio-settings";

type JoinEventDialogProps = {
  onCancel: () => void;
  onJoin: () => void;
  eventSlug?: string;
};
export function JoinEventDialog({
  onCancel,
  eventSlug,
  onJoin,
}: JoinEventDialogProps): React.JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { isValid, dirtyFields, errors, ...state },
  } = useForm<{ code: string }>({ mode: "all" });
  const hasErrors = filterNulls(Object.values(errors)).length > 0;
  useMustBeLoggedIn();
  const [apiError, setApiError] = React.useState<null | string>(null);
  const navigate = useNavigate();
  const [mutate, { loading }] = useEnterEventCodeMutation();
  const onSubmit = React.useCallback(
    async ({ code }: { code: string }) => {
      const trimmed = code?.trim() ?? "";
      if (trimmed.length < 3 || trimmed.length > 100) {
        console.log("Code too short or long");
        return;
      }
      const resp = await mutate({
        variables: { invitation_code: trimmed, event_url_slug: eventSlug },
        refetchQueries: [GqlOps.Query.EventDetailsBySlug540, GqlOps.Query.EventSummaryBySlug],
      });
      console.log("🚀 - file: event-detail.tsx:108 - onSubmit - resp", resp);
      if (resp.data?.lm_join_event?.__typename === "L_Join_Event_Error") {
        setApiError(resp?.data?.lm_join_event?.human_readable_error);
        return;
      }
      onJoin();
      const event_id = resp?.data?.lm_join_event?.event_id;
      if (event_id) {
        navigate(allRoutes.EVENTS.DETAILS.buildPath({ event_id }));
      }
    },
    [mutate, setApiError, navigate]
  );
  return (
    <div>
      <div className="modal cursor-pointer modal-open text-primary !my-0">
        <div className="modal-box">
          <div className="form-control">
            <Text size="sm" as="p" className="label">
              <span className="label-text">Enter Event Invitation Code</span>
            </Text>
            <label className="join">
              <input
                type="text"
                placeholder="event password"
                className="join-item input input-bordered focus:outline-none"
                {...register("code", {
                  required: true,
                  pattern: {
                    value: /[a-zA-Z0-9]*/i,
                    message: "Please enter only alphanumeric characters",
                  },
                  maxLength: {
                    message: "The code isnt that long",
                    value: 100,
                  },
                  minLength: {
                    message: "Please enter at least 3 characters",
                    value: 3,
                  },
                })}
              />
              <SpinnerButton
                className="join-item btn btn-primary"
                disabled={hasErrors || loading}
                loading={loading}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </SpinnerButton>
              <button className="join-item btn btn-accent" onClick={() => onCancel()}>
                cancel
              </button>
            </label>
            <Text size="sm" as="p" className="label px-0 ">
              <span className="label-text-alt text-error">
                {apiError ?? errors.code?.message ?? ""}
              </span>
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}

// function EventInfoBoxLine({
//   topic,
//   hideTopDivider,
//   children,
// }: React.PropsWithChildren<{
//   hideTopDivider?: boolean;
//   topic: string;
// }>): React.JSX.Element {
//   return (
//     <>
//       {!hideTopDivider && <div className=" my-0 divider" />}
//       <div className="w-full max-md:text-xs md:text-sm flex flex-row flex-nowrap justify-between ">
//         <dt className="flex-0 min-w-fit whitespace-nowrap text-right text-ellipsis overflow-hidden">
//           {topic}
//         </dt>
//         <dd className="flex-0 whitespace-nowrap text-right text-ellipsis overflow-hidden">
//           {children}
//         </dd>
//       </div>
//     </>
//   );
// }

type EventDataRoot = NonNullable<EventDetails553Query["events_by_pk"]>;
type EventDataPublicFields =
  | "id"
  | "start"
  | "name"
  | "location_description"
  | "event_attendees"
  | "duration"
  | "tags"
  | "url_slug";
type EventDataPublic = Pick<EventDataRoot, EventDataPublicFields>;
type EventDataPrivate = Omit<EventDataRoot, "__typename" | EventDataPublicFields>;
type EventData = EventDataPublic & Partial<EventDataPrivate>;
type EventDataProp = {
  eventData: EventData;
};

function isAttending(eventData: EventData): boolean {
  return !!eventData.newest_attendees;
}

function EventAttendingToggle({ eventData }: EventDataProp): React.JSX.Element {
  const { loading: verifiedLoading, verified } = useIdentityVerifiedStatus();
  const [leaveMutation, leaveMutationResults] = useLeavePublicEventMutation();
  const [joinMutation, joinMutationResults] = useJoinPublicEvent553Mutation();
  const [showPasscodeModal, setShowPasscodeModal] = React.useState(false);
  const loading = leaveMutationResults.loading || joinMutationResults.loading;
  const my_id = useMyId();
  const navigate = useNavigate();
  const navigateToLogin = useNavigateToLogin();
  const confirm = useConfirm();
  const verifiedConfirm = React.useCallback(async () => {
    if (verifiedLoading || verified) return;
    confirm({
      title: "Verify your profile",
      content:
        "To maintain trust and safety, all users of Simmr need to verify their profile with government ID before joining public events",
      okButtonContent: "Verify ID",
      onOk() {
        navigate(allRoutes.SETTINGS.PRIVACY.buildPath({}));
      },
    });
  }, [verifiedLoading, verified, confirm]);
  const toggleAttending = React.useCallback(
    async (newState: boolean) => {
      if (verifiedLoading) return;
      if (!verified) return verifiedConfirm();
      if (!my_id) {
        console.error("missing ID in EventAttendingToggle");
        return;
      }
      if (newState) {
        joinMutation({
          variables: { event_id: eventData.id, user_id: my_id },
          refetchQueries: [
            GqlOps.Query.EventDetailsBySlug540,
            GqlOps.Query.EventSummaryBySlug,
            GqlOps.Query.EventDetails,
          ],
        });
      } else {
        confirm({
          title: "Leave this event?",
          okButtonContent: "Leave Event",
          onOk: async () => {
            return leaveMutation({
              variables: { my_id, event_id: eventData.id },
              refetchQueries: [
                GqlOps.Query.EventDetailsBySlug540,
                GqlOps.Query.EventSummaryBySlug,
                GqlOps.Query.EventDetails,
              ],
            });
          },
        });
      }
    },
    [
      joinMutation,
      leaveMutation,
      eventData.id,
      my_id,
      confirm,
      verified,
      verifiedLoading,
      verifiedConfirm,
    ]
  );
  if (loading) {
    return <Spinner />;
  }
  return isAttending(eventData) ? (
    <a className=" btn  btn-sm font-semibold" onClick={() => toggleAttending(false)}>
      <FontAwesomeIcon icon={faCheck} />
      Attending
    </a>
  ) : (
    <>
      <a
        className="btn btn-sm btn-primary font-semibold"
        onClick={() =>
          my_id
            ? eventData.is_public
              ? toggleAttending(true)
              : setShowPasscodeModal(true)
            : navigateToLogin()
        }
      >
        {eventData.is_public ? (
          my_id ? (
            "Join Event!"
          ) : (
            "Login / Register to Join"
          )
        ) : (
          <span>
            <FontAwesomeIcon icon={faLock} className="mr-1" />
            {my_id ? "Join with Passcode" : "Login / Register to Join"}
          </span>
        )}
      </a>
      {showPasscodeModal && (
        <JoinEventDialog
          eventSlug={eventData.url_slug}
          onCancel={() => setShowPasscodeModal(false)}
          onJoin={() => setShowPasscodeModal(false)}
        />
      )}
    </>
  );
}

function EventDescription({ eventData }: EventDataProp): React.JSX.Element {
  if (!eventData.description || eventData.description?.trim().length === 0) {
    return <></>;
  }
  const descriptionSplit = eventData.description.split("\n");

  const [expanded, setExpanded] = React.useState(false);
  return (
    <div className="px-6 flex flex-col justify-between text-white">
      <div className="flex flex-row justify-between">
        <p className="flex-0 font-bold md:text-lg max-md:text-md">About Event:</p>
      </div>
      <div className="relative">
        <div className={clsx("overflow-hidden", expanded ? "line-clamp-none" : "line-clamp-3")}>
          {descriptionSplit.map((line, i) => (
            <Linkify
              options={{ target: "_blank", className: "whitespace-pre-line link font-semibold" }}
              as="p"
              key={i}
            >
              {line}
              {"\u00A0"}
            </Linkify>
          ))}
        </div>
        <a
          className="cursor-pointer inline ml-auto text-bold text-[#FE635F]"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "Show Less" : "Show More"}
        </a>
      </div>
    </div>
  );
}

function EventInfoBox({ eventData }: EventDataProp): React.JSX.Element {
  const my_id = useMyId();
  const count = eventData.attendee_count?.attendee_count ?? 0;
  const baseUri = useEnv("REACT_APP_BASE_URI").replace(/\/$/, "");
  const tags = (eventData.tags || []) as Array<keyof typeof EventTags>;
  return (
    <div className="py-3 px-6 flex flex-col justify-between gap-4 text-white">
      <ChipContainer tagCodes={tags} />
      <div className="flex gap-2 justify-between">
        <h3 className="text-xl flex-1">
          <FontAwesomeIcon size="lg" icon={faCalendarDays} />{" "}
          {DateTime.fromISO(eventData.start).toLocaleString(DateTime.DATE_MED)}
        </h3>
        <h3 className="text-xl flex-1">
          <FontAwesomeIcon size="lg" color="#FE635F" icon={faLocationDot} />{" "}
          {eventData.location_description}
        </h3>
      </div>
    </div>
  );
}

// function EventThreads({ eventData }: EventDataProp): React.JSX.Element {
//   const my_id = useMyId();
//   return (
//     <ContentBox>
//       <div className="p-1 flex flex-col justify-between">
//         <p className="font-bold md:text-lg max-md:text-md">
//           Event Message Threads
//         </p>
//         <div className=" my-0 divider" />
//         <ul>
//           {eventData.threads.map(({ id, title }, idx) => (
//             <React.Fragment key={id}>
//               {idx > 0 && <div className=" my-0 divider" />}
//               <li>
//                 <Link
//                   to={authenticatedRoutes.MESSAGING.THREAD.buildPath({
//                     thread_id: id,
//                   })}
//                 >
//                   <FontAwesomeIcon
//                     icon={faMessages}
//                     fixedWidth
//                     className="mr-1"
//                   />
//                   {title ?? `Thread ${idx + 1}`}
//                 </Link>
//               </li>
//             </React.Fragment>
//           ))}
//         </ul>
//       </div>
//     </ContentBox>
//   );
// }

export function EventDetails(): React.JSX.Element {
  // const my_id = useMyId();
  const { event_id } = useParams();
  const { data, loading, error } = useEventSummaryByIdQuery({
    variables: { event_id: event_id ?? "" },
  });
  const navigate = useNavigate();
  React.useEffect(() => {
    const slug = data?.events_by_pk?.url_slug;
    if (slug) {
      navigate(allRoutes.EVENT_FROM_SLUG.buildPath({ slug }));
    }
  }, [navigate, data]);
  React.useEffect(() => {
    if (!loading && !event_id) {
      navigate(allRoutes.HOME.buildPath({}));
    }
  }, [navigate, loading, event_id]);
  if (loading) {
    return <SpinnerFullScreen />;
  }
  if (error) {
    return <ErrorComp caption="error loading event" />;
  }
  return <></>;
  // useMustBeLoggedIn();
  // React.useEffect(() => {
  //   if (!event_id) {
  //     navigate(authenticatedRoutes.me.buildPath({}));
  //   } else {
  //     query({ variables: { event_id } });
  //   }
  // }, [event_id, navigate, query]);
  // const eventData = data?.events_by_pk;
  // if (loading) {
  //   return <SpinnerCentered />;
  // }
  // if (!event_id) {
  //   navigate(authenticatedRoutes.me.buildPath({}));
  //   return <></>;
  // }
  // if (!eventData) {
  //   return <ErrorComp caption={"Could not load event data"} />;
  // }
  // return <EventLayout eventData={eventData} />;
}

export function EventDetailsFromSlug(): React.JSX.Element {
  const my_id = useMyId();
  const { eventId, status } = useMyInfo();
  const { slug } = useTypedParams(allRoutes.EVENT_FROM_SLUG);
  const [mutate] = useRemoveUserFromEvent557Mutation();

  // Include the details and summary query here so that we get a summary of
  // events for non-logged in users
  const { data, loading } = useEventDetailsBySlug553Query({
    skip: !slug || !my_id,
    variables: { url_slug: slug! },
  });

  // Remove users from users from events once they get here
  if (eventId && status === Onboarding_Statuses_Enum.V2_20LiveUser && my_id) {
    mutate({
      variables: {
        event_id: eventId,
        users_id: my_id!,
      },
    });
  }

  const summaryQueryResults = useEventSummaryBySlug553Query({
    variables: { slug: slug ?? "" },
    skip: !slug,
  });

  const navigate = useNavigate();
  const eventData =
    data?.events?.[0] ??
    data?.event_public_summaries?.[0] ??
    summaryQueryResults?.data?.event_public_summaries?.[0];
  useSetPageTitle(loading ? "Event Loading" : (eventData?.name ?? "Event"));
  if (loading || summaryQueryResults.loading) return <SpinnerCentered />;

  if (!slug) {
    navigate(allRoutes.me.buildPath({}));
    return <></>;
  }
  if (!eventData) return <ErrorComp caption={"Could not load event data"} />;

  // Annoying little hack to make TS happy
  const { id, name, start, duration, url_slug } = eventData;
  if (!id || !name || !start || !duration || !url_slug) {
    return <ErrorComp caption={"Could not load event data"} />;
  }

  const eventDataChecked: EventData = {
    ...eventData,
    id,
    name,
    start,
    url_slug,
    duration,
    is_public: !!eventData.is_public,
  };
  return <EventLayout eventData={eventDataChecked} />;
}

function EventLayout({ eventData }: EventDataProp): React.JSX.Element {
  const event_id = eventData.id;
  const my_id = useMyId();
  const myAttendingStatus = React.useMemo(
    () => eventData.event_attendees.find((at) => at.user_id === my_id)?.status,
    [event_id, my_id, eventData]
  );
  const baseUri = useEnv("REACT_APP_BASE_URI");
  const eventImgUrl =
    baseUri.replace(/\/$/, "") +
    allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({
      slug: eventData.url_slug,
    }) +
    `?mid=${eventData.media_upload_id}}`;
  // const [userFilter, setUserFilter] = React.useState("");
  // const filteredAttendees = React.useMemo(() => {
  //   if (!eventData.newest_attendees) {
  //     return null;
  //   }
  //   const trimmedFilter = userFilter.trim().toLowerCase();
  //   if (trimmedFilter.length === 0) {
  //     return filterNulls(
  //       eventData.newest_attendees.map((a) => profileListItemFromSummary(a.user_summary))
  //     );
  //   }
  //   return filterNulls(
  //     eventData.newest_attendees
  //       .filter(
  //         (a) =>
  //           a.user_summary?.slug?.toLowerCase?.()?.match?.(trimmedFilter) ||
  //           a.user_summary?.screen_name?.toLowerCase()?.match?.(trimmedFilter)
  //       )
  //       .map((a) => profileListItemFromSummary(a.user_summary))
  //   );
  // }, [userFilter, eventData]);
  const share = useShare();
  const { verified } = useIdentityVerifiedStatus();

  const userIsAttending = isAttending(eventData);

  const [leaveMutation, leaveMutationResults] = useLeavePublicEventMutation();
  const [joinMutation, joinMutationResults] = useJoinPublicEvent553Mutation();
  const confirm = useConfirm();
  const setAttending = React.useCallback(
    async (willAttend: boolean, status: "new" | "maybe" = "new") => {
      if (!my_id) {
        console.error("missing ID in EventAttendingToggle");
        return;
      }

      setTempAttendingStatus(willAttend ? status : undefined); // This is required because otherwise the button will flicker when changing state

      if (willAttend) {
        joinMutation({
          variables: { event_id: eventData.id, user_id: my_id, status },
          refetchQueries: [
            GqlOps.Query.EventDetailsBySlug553,
            GqlOps.Query.EventSummaryBySlug553,
            // GqlOps.Query.EventDetails,
          ],
        });
      } else {
        leaveMutation({
          variables: { my_id, event_id: eventData.id },
          refetchQueries: [
            GqlOps.Query.EventDetailsBySlug553,
            GqlOps.Query.EventSummaryBySlug553,
            // GqlOps.Query.EventDetails,
          ],
        });
      }
    },
    [joinMutation, leaveMutation, eventData.id, my_id, confirm, verified]
  );

  const [changingStatus, setChangingStatus] = React.useState(false);

  const [tempAttendingStatus, setTempAttendingStatus] = React.useState(myAttendingStatus);

  function startChangeStatus() {
    setChangingStatus(true);
  }

  function stopChangingStatus() {
    setChangingStatus(false);
  }

  // Workaround to allow the object to be indexed with undefined
  const humanReadableStatuses: Record<string, string> = {
    new: "Attending",
    maybe: "Tentative",
  };

  return (
    <div className="h-full flex flex-col justify-stretch items-stretch text-white p-0 bg-[#2A2C38] pt-5 pb-14">
      <div className="px-6 mb-4">
        <VerificationBox
          heading="✨ Verify for Full Event Features! ✨"
          text="Tap here to verify your identity and gain access to the attendee list and event chat. Verification is quick and opens up all the features!"
        />
      </div>
      {eventImgUrl && (
        <div
          className="w-full aspect-video bg-no-repeat bg-cover bg-center flex p-3 flex-col items-end"
          style={{ backgroundImage: `url(${eventImgUrl})` }}
        >
          {/* <div className="flex gap-2">
            <button
              className="bg-white p-4 bg-opacity-60 rounded-lg"
              onClick={(e) => {
                e.preventDefault();
                // TODO: Share logic
              }}
            >
              <FontAwesomeIcon icon={faPaperPlane} size="2xl" className="text-Simmr-debug-500" />
            </button>
            <button
              className=" bg-white p-4 bg-opacity-60 rounded-lg"
              onClick={(e) => {
                e.preventDefault();
                // TODO: Heart logic
              }}
            >
              <FontAwesomeIcon icon={faHeart} size="2xl" className="text-Simmr-debug-500" />
            </button>
          </div> */}
        </div>
      )}
      <EventInfoBox eventData={eventData} />
      <EventDescription eventData={eventData} />
      <Link
        to={allRoutes.EVENT_FROM_SLUG.ATTENDEES.buildPath({ slug: eventData.url_slug })}
        className="mt04 px-6 flex items-center gap-2"
      >
        {eventData.event_attendees.slice(0, 5).map(({ user_summary }, i) => (
          <Avatar
            key={i}
            slug={user_summary?.slug!}
            className="border-2 border-white first:ml-0 -ml-4 inline-block"
            tailwindSize="10"
          />
        ))}
        <Text size="sm" as="p" className="text-lg text-[#FE635F]">
          {eventData.event_attendees.length}+ attending
        </Text>
      </Link>

      <div className="p-1 lg:p-2  flex flex-row justify-center gap-2 max-md:text-sm whitespace-nowrap px-6 pb-20">
        {changingStatus ? (
          <BigModal className="flex text-white text-center flex-col gap-4 p-12 !bg-[#2A2C38]">
            <div className="flex flex-col gap-4 card p-6 pt-0 !bg-[#2A2C38]">
              <button
                className="self-end p-x pt-4 text-white transition duration-200 ease-in-out rounded-full hover:bg-gray-200"
                onClick={() => {
                  setChangingStatus(false);
                }}
              >
                <FontAwesomeIcon icon={faX} />
              </button>
              {match(tempAttendingStatus)
                .with("new", () => (
                  <>
                    <Text size="sm" as="h1" className="text-2xl font-bold">
                      Change RSVP?
                    </Text>
                    <Text size="sm" as="p" className="text-md">
                      Your current RSVP is <span className="font-bold">Joined</span>
                    </Text>
                    <Button
                      onClick={() => {
                        setAttending(true, "maybe");
                        setChangingStatus(false);
                      }}
                    >
                      Change my RSVP to Maybe
                    </Button>
                    <Button
                      onClick={() => {
                        setTempAttendingStatus("cancelConfirm");
                      }}
                      variant="neutral"
                    >
                      Cancel my RSVP
                    </Button>
                  </>
                ))
                .with("maybe", () => (
                  <>
                    <Text size="sm" as="h1" className="text-2xl font-bold">
                      Edit RSVP?
                    </Text>
                    <Text size="sm" as="p" className="text-md">
                      Your current RSVP is <span className="font-bold">Maybe</span>
                    </Text>
                    <Button
                      onClick={() => {
                        setAttending(true);
                        setChangingStatus(false);
                      }}
                    >
                      Change my RSVP to Attending
                    </Button>
                    <Button
                      onClick={() => {
                        setTempAttendingStatus("cancelConfirm");
                      }}
                      variant="neutral"
                    >
                      Cancel my RSVP
                    </Button>
                  </>
                ))
                .with("cancelConfirm", () => (
                  <>
                    <Text size="sm" as="h1" className="text-2xl font-bold">
                      Cancel RSVP?
                    </Text>
                    <Text size="sm" as="p" className="text-md">
                      If you were accepted into a guest list and you cancel the RSVP, you may need
                      to re-request access
                    </Text>
                    <Button
                      onClick={() => {
                        setAttending(false);
                        setChangingStatus(false);
                      }}
                      variant="neutral"
                    >
                      Cancel my RSVP
                    </Button>
                    <Button
                      onClick={() => {
                        setChangingStatus(false);
                        setTempAttendingStatus("new");
                      }}
                    >
                      Keep my current RSVP
                    </Button>
                  </>
                ))
                .otherwise(() => (
                  <></>
                ))}
            </div>
          </BigModal>
        ) : (
          match(tempAttendingStatus)
            .with("new", () => (
              <div className="flex w-full flex-col gap-2">
                <div className="flex w-full gap-2">
                  <Button>Attending</Button>
                  {event_id && my_id === eventData?.owner_id ? (
                    <Link to={allRoutes.EVENTS.DETAILS.EDIT.buildPath({ event_id })}>
                      <Button variant="neutral">Edit Event</Button>
                    </Link>
                  ) : (
                    <Button onClick={startChangeStatus} variant="neutral">
                      Change my RSVP
                    </Button>
                  )}
                </div>
                {eventData?.threads?.[0] && (
                  <Link
                    to={allRoutes.MESSAGING.LIST.THREAD.buildPath({
                      thread_id: eventData?.threads[0].id ?? "foo",
                    })}
                  >
                    <Button variant="primary">Go to Event Chat</Button>
                  </Link>
                )}
              </div>
            ))
            .with("maybe", () => (
              <div className="flex flex-col w-full gap-2">
                <Button>Maybe</Button>
                <Button onClick={startChangeStatus} variant="neutral">
                  Change my RSVP
                </Button>
              </div>
            ))
            .otherwise(() => (
              <>
                <Button
                  onClick={() => {
                    setAttending(true, "maybe");
                  }}
                  variant="neutral"
                >
                  Maybe
                </Button>
                <Button
                  onClick={() => {
                    setAttending(true);
                  }}
                  variant="primary"
                >
                  Join Event
                </Button>
              </>
            ))
        )}
      </div>
      {/* {filteredAttendees && (
      <ContentBox className="">
        <div className="p-1">
        <p className="font-bold md:text-lg max-md:text-md">
          Attendees
          <span>
          {" "}
          {filteredAttendees.length === totalAttendees
            ? `(${totalAttendees})`
            : `(${filteredAttendees.length}/${totalAttendees})`}
          </span>
        </p>
        <div className=" my-0 divider" />
        <div className="my-2">
          <InputLeftAddon value={userFilter} onChange={(e) => setUserFilter(e.target.value)}>
          <FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth />
          </InputLeftAddon>
        </div>
        <ProfileList
          profiles={filteredAttendees}
          className={!verified ? "pointer-events-none select-none blur-sm" : ""}
          mkRoute={(profile) =>
          profile.slug
            ? {
              to: allRoutes.PROFILE.buildPath({
              slug: profile.slug ?? "",
              }),
            }
            : null
          }
        />
        </div>
      </ContentBox>
      )} */}
    </div>
  );
}
