import {
  Onboarding_Statuses_Enum,
  useEventSlugFromId556Query,
  useUserUrlSlug530Query,
  useUserUrlSlug556Query,
} from "shared/dist/__generated__/components";

import React from "react";
import { useApolloClient } from "shared/dist/apollo";
import { useMyId } from "shared/dist/auth-data";
import { useParams } from "react-router-dom";

export function useRefetchBioV2() {
  const { slug: mySlug } = useIsMyPage();
  const cache = useApolloClient().cache;
  const refetch = React.useCallback(() => {
    const bioResponseId = cache.identify({
      __typename: "L_Bio_V2_Success",
      id: mySlug,
    });
    cache.evict({ broadcast: true, id: bioResponseId });
  }, [cache, mySlug]);
  return refetch;
}

export function useRefetchMyBio() {
  const { slug: mySlug } = useIsMyPage();
  const cache = useApolloClient().cache;
  const refetch = React.useCallback(() => {
    const bioResponseId = cache.identify({
      __typename: "L_Bio_Public_Response",
      id: mySlug,
    });
    cache.evict({ broadcast: true, id: bioResponseId });
  }, [cache, mySlug]);
  return refetch;
}

export function useIsMyPage() {
  const { slug, loading } = useMySlug() ?? {};
  const { slug: paramsSlug } = useParams();
  if (loading) {
    return { slug, loading: true, isMyPage: undefined, error: undefined };
  }
  if (!slug) {
    return {
      slug: undefined,
      loading: false,
      isMyPage: undefined,
      error: "Unable to load slug",
    };
  }
  return {
    slug,
    loading: false,
    isMyPage: slug === paramsSlug,
    error: undefined,
  };
}

/**
 *
 * @returns @deprecated use useMyInfo instead
 */
export function useMySlug(): null | {
  slug: string;
  loading: boolean;
  status: Onboarding_Statuses_Enum;
  refetch: () => Promise<unknown>;
} {
  const user_id = useMyId();
  const { data, loading, refetch } = useUserUrlSlug530Query({
    fetchPolicy: "cache-and-network",
    skip: !user_id,
    variables: { user_id: user_id ?? "" },
  });
  const user = data?.users_by_pk;
  const slug = user?.url_slug?.slug ?? null;
  // console.log("🚀 ~ file: bio-settings.tsx:45 ~ slug:", slug);
  const status = user?.onboarding_status ?? null;
  if (!slug || !status) {
    return null;
  }
  return {
    slug,
    loading,
    status,
    refetch,
  };
}

export function useMyInfo():
  | {
      slug?: null;
      id?: null;
      screenName?: null;
      loading: true;
      status?: null;
      error?: undefined;
      eventSlug?: string | null;
      eventId?: string | null;
      refetch: () => Promise<unknown>;
    }
  | {
      error: "missing slug" | "missing status" | "missing user";
      slug?: null;
      id?: null;
      screenName?: null;
      loading: boolean;
      status?: null;
      eventSlug?: string | null;
      eventId?: string | null;
      refetch: () => Promise<unknown>;
    }
  | {
      slug: string;
      id: string;
      screenName: string;
      loading: boolean;
      status: Onboarding_Statuses_Enum;
      eventSlug?: string | null;
      eventId?: string | null;
      refetch: () => Promise<unknown>;
    } {
  const user_id = useMyId();
  const { data, loading, refetch } = useUserUrlSlug556Query({
    variables: { user_id: user_id ?? "" },
    fetchPolicy: "cache-and-network",
    skip: !user_id,
  });

  const eventId = data?.users_by_pk?.user_from_event?.event_id;
  const { data: eventData, error: eventError } = useEventSlugFromId556Query({
    variables: { event_id: eventId! },
    skip: !eventId,
  });

  const user = data?.users_by_pk;
  if (user) {
    if (!user.url_slug) return { loading, error: "missing slug", refetch };

    return {
      slug: user.url_slug.slug,
      screenName: user.screen_name,
      id: user.id,
      eventSlug: eventData?.events_by_pk?.url_slug,
      loading,
      status: user.onboarding_status,
      eventId,
      refetch,
    };
  }
  return {
    slug: null,
    screenName: null,
    id: null,
    loading,
    status: null,
    eventSlug: null,
    eventId: null,
    refetch,
    error: "missing user",
  };
}
