import clsx from "clsx";

export type TextProps = {
  as?: "div" | "em" | "p" | "span" | "strong" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  size?: "xl" | "lg" | "md" | "sm" | "xs" | "xxs" | "tiny";
  weight?: "bold" | "medium" | "semibold" | "regular" | "light" | "thin";
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  variant?: "default" | "display";
  underline?: boolean;
  className?: string;
  children: React.ReactNode;
};

const headingSizes: {
  [key: number]: { size: "xl" | "lg" | "md" | "sm" | "xs"; weight: "bold" | "medium" };
} = {
  1: { size: "xl", weight: "bold" },
  2: { size: "lg", weight: "bold" },
  3: { size: "md", weight: "bold" },
  4: { size: "sm", weight: "bold" },
  5: { size: "xs", weight: "bold" },
  6: { size: "xs", weight: "medium" },
};

export const Text = ({
  as,
  size = "md",
  weight = "regular",
  level,
  underline = false,
  variant = "default",
  className,
  children,
  ...props
}: TextProps) => {
  const Tag = level ? (`h${level}` as "h1" | "h2" | "h3" | "h4" | "h5" | "h6") : as || "p";

  const headingStyle = level ? headingSizes[level] : null;
  const textSize = headingStyle?.size || size;
  const textWeight = headingStyle?.weight || weight;

  return (
    <Tag
      className={clsx(
        {
          xl: "text-[48px] leading-[76.8px]",
          lg: "text-[40px] leading-[64px]",
          md: "text-[32px] leading-[51.2px]",
          sm: "text-[24px] leading-[38.4px]",
          xs: "text-[18px] leading-[28.8px]",
          xxs: "text-[16px] leading-[18.8px]",
          tiny: "text-[14px] leading-[22.4px]",
        }[textSize],
        {
          bold: "font-bold",
          semibold: "font-semibold",
          medium: "font-medium",
          regular: "font-regular",
          light: "font-light",
          thin: "font-thin",
        }[textWeight],
        variant === "display" && "font-playfair",
        underline &&
          "underline underline-offset-[3px] decoration-1 text-underline-position-from-font decoration-skip-ink-none",
        "text-white",
        className
      )}
      {...props}
    >
      {children}
    </Tag>
  );
};
