import { ProfileList, ProfileListItem, ProfileListProps } from "../../../widgets/profile-list";
import { classNames, filterNulls } from "shared/dist/util";
import { useCallback, useMemo } from "react";

import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { SimmerZeroScreen, ZeroScreen } from "../../../widgets/zero-screens";
import { allRoutes } from "../../../util/routes";
import { navHeaderState } from "shared-web-react/dist/widgets/nav-stack-view";
import { useMyId } from "shared/dist/auth-data";
import {
  useMyVouches555Query,
  useMyVouchesQuery,
  useMyVouchesTest555Query,
} from "shared/dist/__generated__/components";
import { useNavigate } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { useMyInfo, useMySlug } from "../../bio/bio-settings";

export function MyVouches(): React.JSX.Element {
  const my_id = useMyId();
  // const mySlug ()?.slug;
  const { slug: my_slug } = useMyInfo();
  const navigate = useNavigate();
  const { slug } = useTypedParams(allRoutes.PROFILE.VOUCHES);
  const isMyPage = slug === my_slug;
  const myvouches = useMyVouchesQuery({
    variables: { my_id: my_id! },
  });
  console.log(my_id, myvouches);
  const { data, loading, error } = useMyVouches555Query({
    variables: { slug: slug ?? "foo" },
  });

  const mkRoute: ProfileListProps["mkRoute"] = useCallback(
    ({ slug, ...other }: ProfileListItem) =>
      slug
        ? {
            to: allRoutes.PROFILE.buildPath({ slug }),
            state: navHeaderState("Back to my likes"),
          }
        : null,
    []
  );

  const items: Array<ProfileListItem> = useMemo(() => {
    return filterNulls(
      data?.vouches?.map((v) => {
        return {
          screenName: v?.author_summary?.screen_name || "",
          slug: v?.author_summary?.slug || null,
        };
      })
    );
  }, [data]);

  if (loading) return <SpinnerCentered />;

  return items.length === 0 ? (
    <div className="flex flex-col h-full w-full overflow-hidden items-stretch px-4 text-white">
      {isMyPage ? (
        <SimmerZeroScreen
          header="No vouches yet"
          text="Vouches are a way to show appreciation for someone's work. When someone vouches for you, it will show up here."
          btnText="Discover new people"
          clickHandler={() => {
            navigate(allRoutes.DISCOVERY.buildPath({}));
          }}
        />
      ) : (
        <SimmerZeroScreen
          header="No vouches yet"
          text="This user hasn't received any vouches yet. Check back later!"
          btnText="Discover new people"
          clickHandler={() => {
            navigate(allRoutes.DISCOVERY.buildPath({}));
          }}
        />
      )}
    </div>
  ) : (
    <div
      className={classNames("flex justify-start flex-col join join-vertical gap-4 h-full relative")}
    >
      <div className={classNames("p-4 pb-16 max-h-full overflow-y-auto")}>
        <ProfileList
          profiles={items}
          mkRoute={mkRoute}
          columnCountOverride={1}
          className="text-white"
        />
      </div>
    </div>
  );
}
