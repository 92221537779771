import {
  Event_Summary_412Fragment,
  useMyPastEvents412Query,
  useMyUpcomingEvents412Query,
  useMyJoinedEvents557Query,
  usePublicUpcomingEventList552Query,
} from "shared/dist/__generated__/components";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Maybe, classNames } from "shared/dist/util";
import React, { useEffect, useState } from "react";
import { UserPageTabLabel } from "../../../widgets/tabs";
import { NavigationPill } from "../../../widgets/nav-pill";
import {
  faChevronDown,
  faLocationDot,
  faPaperPlane,
  faHeart,
} from "@fortawesome/pro-regular-svg-icons";

import { Container } from "../../../widgets/container";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShowIndicator } from "shared/dist/indicators-context";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { VerificationBox } from "../../../widgets/verification-box";
import { allRoutes } from "../../../util/routes";
import clsx from "clsx";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { useIndicators } from "../../../widgets/indicators";
import { useMkUrl } from "shared/dist/util/env";
import { useSecureStorage } from "../../../widgets/use-secure-storage";
import { useSetPageTitle } from "shared-web-react/dist/widgets/nav-stack-view";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Text } from "../../../components/atoms/Text";
import mixpanel from "mixpanel-browser";
import { useMyId } from "shared/dist/auth-data";
import { SimmerZeroScreen } from "../../../widgets/zero-screens";
enum EventSortMode {
  COMING_UP = "Coming Up",
}

export function EventsHome(): React.JSX.Element {
  const [sortMode, setSortMode] = useState(EventSortMode.COMING_UP);
  useSetPageTitle("Events");
  return (
    <div
      className={classNames(
        "h-full flex flex-col justify-stretch items-stretc text-white bg-[#2A2C38] px-6"
      )}
    >
      <div className={classNames("flex-0 fit-content relative")}>
        <div className="tabs-xs md:tabs-sm lg:tabs-md tabs-bordered tabs flex w-full gap-2 mb-2 flex-nowrap justify-around ">
          <NavigationPill hideIndicator route={allRoutes.EVENTS.LIST.buildPath({})}>
            <ShowIndicator
              prefix={allRoutes.EVENTS.LIST.buildPath({})}
              indicatorItemClassName="left-11"
            >
              <UserPageTabLabel>All Events</UserPageTabLabel>
            </ShowIndicator>
          </NavigationPill>
          {/* <NavigationPill route={allRoutes.EVENTS.LIST.MY_UPCOMING.buildPath({})}>
            <UserPageTabLabel>Saved</UserPageTabLabel>
          </NavigationPill> */}
          <NavigationPill route={allRoutes.EVENTS.LIST.MY_JOINED.buildPath({})}>
            <UserPageTabLabel>Joined Events</UserPageTabLabel>
          </NavigationPill>
        </div>
        {/* <div className="dropdown dropdown-end relative">
          <label tabIndex={0} className="flex justify-center items-center cursor-pointer">
            Sort By: {sortMode} <FontAwesomeIcon icon={faChevronDown} className="mx-2" />
          </label>
          <ul
            tabIndex={0}
            className=" mt-[23px] mr-[-16px] dropdown-content whitespace-nowrap text-base menu p-2 shadow border-white border-2 rounded-md"
          >
            {Object.values(EventSortMode).map((mode, i) => (
              <li key={i}>
                <a
                  className={classNames("text-base-100 hover:!text-base-200 whitespace-nowrap")}
                  onClick={() => {
                    const elem = document.activeElement;
                    if (elem instanceof HTMLElement) {
                      elem?.blur();
                    }

                    setSortMode(mode);
                  }}
                >
                  {mode}
                </a>
              </li>
            ))}
          </ul>
        </div> */}
      </div>
      <div className={classNames("flex-1  max-h-full overflow-auto")}>
        <Outlet />
      </div>
    </div>
  );
}
function eventFragmentToItem(
  fragment: Event_Summary_412Fragment
): Omit<EventListItemProps, "initialLastCheckedEvents"> {
  return {
    name: fragment.name,
    start: DateTime.fromISO(fragment.start),
    location: fragment.location_description,
    slug: fragment.url_slug,
    imageMediaUploadId: fragment.media_upload_id,
    event_attendees: [],
  };
}

export function EventsPublic(): React.JSX.Element {
  const { data, loading } = usePublicUpcomingEventList552Query({ variables: { offset: 0 } });
  const { getSecureValue, setSecureValue } = useSecureStorage("events");
  const [initialLastChecked, setInitialLastChecked] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { clearEventsIndicator } = useIndicators();

  useEffect(() => {
    const fetchInitialDate = async () => {
      const value = await getSecureValue();
      setInitialLastChecked(value || DateTime.now().minus({ days: 7 }).toISO());
      setIsLoading(false);
    };

    fetchInitialDate();
  }, [getSecureValue]);

  useEffect(() => {
    if (!isLoading && !loading) {
      const newLastChecked = DateTime.now().toISO();
      setSecureValue(newLastChecked!);
      clearEventsIndicator();
    }
  }, [isLoading, loading, setSecureValue, clearEventsIndicator]);

  const items: Array<EventListItemProps> = React.useMemo(
    () =>
      data?.event_public_summaries?.map((e) => ({
        connectionsAttending: e.connections_attending ?? 0,
        event_attendees: e.event_attendees,
        created_at: e.created_at ?? undefined,
        imageMediaUploadId: e.media_upload_id,
        location: e.location_description,
        name: e.name!,
        slug: e.url_slug!,
        start: DateTime.fromISO(e.start!),
        initialLastCheckedEvents: initialLastChecked,
      })) ?? [],
    [data, initialLastChecked]
  );

  if (loading || isLoading) return <SpinnerCentered />;

  return <EventList items={items} />;
}

export function EventsMyPast(): React.JSX.Element {
  const { data, loading } = useMyPastEvents412Query({});
  const { lastChecked: events_last_checked } = useSecureStorage("events");
  const initialLastCheckedEvents = React.useRef<string>(
    events_last_checked || DateTime.now().minus({ days: 7 }).toISO()
  );

  const items: Array<EventListItemProps> = React.useMemo(
    () =>
      data?.events?.map((event) => ({
        ...eventFragmentToItem(event),
        initialLastCheckedEvents: initialLastCheckedEvents.current,
      })) ?? [],
    [data, initialLastCheckedEvents]
  );
  return <div>{loading ? <SpinnerCentered /> : <EventList items={items} />}</div>;
}

export function EventsMyJoined() {
  const my_id = useMyId();
  const navigate = useNavigate();
  const { data, loading } = useMyJoinedEvents557Query({ variables: { my_id: my_id || "" } });

  const { upcoming, past } = React.useMemo(
    () => ({
      upcoming:
        data?.upcoming?.map((e) => ({
          event_attendees: e.event_attendees,
          imageMediaUploadId: e.media_upload_id,
          location: e.location_description,
          name: e.name!,
          slug: e.url_slug!,
          start: DateTime.fromISO(e.start!),
          initialLastCheckedEvents: null,
        })) ?? [],
      past:
        data?.past?.map((e) => ({
          event_attendees: e.event_attendees,
          imageMediaUploadId: e.media_upload_id,
          location: e.location_description,
          name: e.name!,
          slug: e.url_slug!,
          start: DateTime.fromISO(e.start!),
          initialLastCheckedEvents: null,
        })) ?? [],
    }),
    [data]
  );

  if (loading) return <SpinnerCentered />;

  return (
    <div>
      {/* Zero Screen Component */}
      {upcoming.length === 0 && past.length === 0 && (
        <SimmerZeroScreen
          header="No upcoming events yet"
          text="Events are a great way to meet people in the wild. Browse events and see who's attending near you."
          btnText="Browse Events"
          clickHandler={() => navigate(allRoutes.EVENTS.LIST.path)}
        />
      )}

      {/* If both upcoming and past have events */}
      {upcoming.length > 0 && past.length > 0 && (
        <div>
          <div>
            <Text as="h2" size="sm">
              Upcoming
            </Text>
            <EventList items={upcoming} />
          </div>

          <div>
            <Text as="h2" size="sm">
              Past Events
            </Text>
            <EventList items={past} />
          </div>
        </div>
      )}

      {/* If upcoming is empty but past has events */}
      {upcoming.length === 0 && past.length > 0 && (
        <div>
          <div>
            <SimmerZeroScreen
              header="No upcoming events yet"
              text="Events are a great way to meet people in the wild. Browse events and see who's attending near you."
              btnText="Browse Events"
              clickHandler={() => navigate(allRoutes.EVENTS.LIST.path)}
            />
          </div>

          <div>
            <Text as="h2" size="sm">
              Past Events
            </Text>
            <EventList items={past} />
          </div>
        </div>
      )}

      {/* If only upcoming events exist */}
      {upcoming.length > 0 && past.length === 0 && (
        <div>
          <Text as="h2" size="sm">
            Upcoming
          </Text>
          <EventList items={upcoming} />
        </div>
      )}
    </div>
  );
}

export function EventsMyUpcoming(): React.JSX.Element {
  const { data, loading } = useMyUpcomingEvents412Query({});
  const { getSecureValue, setSecureValue } = useSecureStorage("events");
  const [initialLastChecked, setInitialLastChecked] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const fetchInitialDate = async () => {
      const value = await getSecureValue();
      setInitialLastChecked(value || DateTime.now().minus({ days: 7 }).toISO());
      setIsLoading(false);
    };

    fetchInitialDate();
  }, [getSecureValue]);

  useEffect(() => {
    if (!isLoading) {
      setSecureValue(DateTime.now().toISO()!);
    }
  }, [isLoading, setSecureValue]);

  const items: Array<EventListItemProps> = React.useMemo(
    () =>
      data?.events?.map((event) => ({
        ...eventFragmentToItem(event),
        initialLastCheckedEvents: initialLastChecked,
      })) ?? [],
    [data, initialLastChecked]
  );

  if (loading || isLoading) return <SpinnerCentered />;

  return <EventList items={items} />;
}
type EventListProps = {
  items: Array<EventListItemProps>;
  fakeData?: boolean;
};
export function EventList({ items, fakeData }: EventListProps): React.JSX.Element {
  return (
    <Container size="xxs" className={clsx("EventList px-2 pb-4")}>
      <ul className={clsx("space-y-2")}>
        <div className="mb-1">
          <VerificationBox
            heading="✨ Exclusive Events Await! ✨"
            text="Verification takes just a moment and unlocks all the fantastic features and events on Simmr. Tap here to verify your identity now!"
          />
        </div>
        {fakeData &&
          [...Array(5)].map((_, idx) => (
            <li key={idx}>
              <EventListItem
                connectionsAttending={1}
                event_attendees={[]}
                dummyImage
                slug={`event_${idx}`}
                name={`Test Event ${idx}`}
                start={DateTime.fromISO("2021-10-10")}
                location={`city number ${idx}`}
                initialLastCheckedEvents={DateTime.now().minus({ days: 7 }).toISO()}
                created_at={DateTime.now().toISO()!}
              />
            </li>
          ))}
        {!fakeData &&
          items.map((item) => (
            <li key={item.slug}>
              <EventListItem {...item} />
            </li>
          ))}
      </ul>
    </Container>
  );
}

type EventListItemProps = {
  connectionsAttending?: number;
  event_attendees: Array<{
    user_summary?: {
      screen_name?: string | null;
      profile_image_id?: string | null;
      slug?: string | null;
    } | null;
  }>;
  dummyImage?: boolean;
  imageMediaUploadId?: Maybe<string>;
  location?: Maybe<string>;
  name: string;
  slug: string;
  start: DateTime;
  created_at?: string;
  initialLastCheckedEvents: string | null;
};
export function EventListItem({
  connectionsAttending,
  event_attendees,
  dummyImage,
  imageMediaUploadId,
  location,
  name,
  slug,
  start,
  created_at,
  initialLastCheckedEvents,
}: EventListItemProps): React.JSX.Element {
  const path = allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({ slug });
  const mkUrl = useMkUrl();
  const isNewEvent = React.useMemo(() => {
    if (created_at && initialLastCheckedEvents) {
      const createdAtDate = DateTime.fromISO(created_at);
      const lastCheckedDate = DateTime.fromISO(initialLastCheckedEvents);

      if (createdAtDate.isValid && lastCheckedDate.isValid) {
        return createdAtDate > lastCheckedDate;
      }
    }
    return false;
  }, [created_at, initialLastCheckedEvents]);

  const eventImgUrl = dummyImage
    ? "https://picsum.photos/1024/522"
    : mkUrl(path) + (imageMediaUploadId ? `?mid=${imageMediaUploadId}` : "");

  return (
    <>
      {isNewEvent && (
        <div className={clsx("flex flex-row justify-stretch items-center")}>
          <div className={clsx("flex-1 h-[1px] w-full border border-candid-lavender-200")} />
          <div className="flex-0 text-xs mx-2">
            <FontAwesomeIcon icon={faSparkles} className="mx-1 text-candid-red-300" />
            New Event
          </div>
          <div className={clsx("flex-1 h-[1px] w-full border border-candid-lavender-200")} />
        </div>
      )}
      <Link
        onClick={() => {
          mixpanel.track("Event Card Clicked", {
            eventName: name,
          });
        }}
        to={allRoutes.EVENT_FROM_SLUG.buildPath({ slug })}
        className={clsx("flex flex-row justify-stretch items-center gap-2 py-1")}
      >
        <div
          className={clsx(
            "card w-96 bg-base-100 shadow-sm shadow-black",
            "w-full aspect-square bg-no-repeat bg-cover bg-center overflow-hidden"
          )}
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${eventImgUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="h-full p-4 flex flex-col items-start justify-between">
            <div className="flex justify-between">
              <div className="aspect-square flex flex-col items-center justify-center px-2">
                <Text as="h1" className="font-bold text-[#424242]" size="md">
                  {start.day}
                </Text>
                <Text
                  as="h2"
                  className="text-md tracking-widest uppercase text-[#424242]"
                  size="xxs"
                >
                  {start.monthLong}
                </Text>
              </div>
              {/* <div className="flex items-start justify-center gap-2">
                <button
                  className="bg-white p-3 bg-opacity-60 rounded-lg"
                  onClick={(e) => {
                    e.preventDefault();
                    // TODO: Share logic
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    size="xl"
                    className="text-candid-debug-500"
                  />
                </button>
                <button
                  className=" bg-white p-3 bg-opacity-60 rounded-xl"
                  onClick={(e) => {
                    e.preventDefault();
                    // TODO: Heart logic
                  }}
                >
                  <FontAwesomeIcon icon={faHeart} size="lg" className="text-candid-debug-500" />
                </button>
              </div> */}
            </div>
            <div className="px-4 py-2 flex-shrink">
              <Text as="h1" className="text-3xl font-bold" size="xs">
                {name}
              </Text>
              <Text as="h2" className="text-2xl" size="xs">
                <FontAwesomeIcon icon={faLocationDot} /> {location}
              </Text>
              <div className="flex items-center gap-2">
                {event_attendees.slice(0, 5).map(({ user_summary }, i) => (
                  <Avatar
                    key={i}
                    slug={user_summary?.slug!}
                    className="border-2 border-white first:ml-0 -ml-4 inline-block"
                    tailwindSize="10"
                  />
                ))}
                <Text as="p" size="xs">
                  {event_attendees.length} attending
                </Text>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
