import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import React, { HTMLProps, useEffect } from "react";
import {
  faCakeCandles,
  faCamera,
  faGlobe,
  faLocationDot,
  faLock,
  faPencil,
  faPenToSquare,
  faPeopleGroup,
  faRecycle,
  faSquarePen,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { useRefetchBioV2, useRefetchMyBio } from "../../screens/bio/bio-settings";
import addButton from "../../assets/add-button.png";
import { ImageUploader } from "../../widgets/images";
import { IndicatorNavLink } from "../indicator-nav-link";
import { JoinEventDialog } from "../../screens/other-pages/event-detail";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { faCirclePlus } from "@fortawesome/pro-duotone-svg-icons";
// import { useApolloClient } from "shared/dist/apollo";
import { useIsDev } from "shared/dist/util/env";
import { useMyId } from "shared/dist/auth-data";
import { useNavigate } from "react-router-dom";
import { useRefetchMyBioSummary } from "../../screens/user-page/common";
import { faGalleryThumbnails, faImages } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "../../components/atoms/Button";
import { GqlOps, useSetStatusMutation } from "shared/dist/__generated__/components";
import { StatusUpdate } from "../../screens/user-page/profile/status-update";
// import { Camera, CameraResultType } from "@capacitor/camera";
import { Text } from "../../components/atoms/Text";
import mixpanel from "mixpanel-browser";

type NavbarActions = { onClick: HTMLProps<HTMLAnchorElement>["onClick"] } | { to: string } | {};
export type NavbarDropdownProps = {
  children: React.ReactNode;
  status?: "coming soon" | "beta";
} & Pick<FontAwesomeIconProps, "icon"> &
  NavbarActions;

// async function takeAndUploadPhoto() {
//   try {
//     // Take the picture
//     const image = await Camera.getPhoto({
//       quality: 90,
//       allowEditing: false,
//       resultType: CameraResultType.Base64,
//     });

//     // Convert base64 to blob for upload
//     const response = await fetch(`data:image/jpeg;base64,${image.base64String}`);
//     const blob = await response.blob();
//     const file = new File([blob], "photo.jpg", { type: "image/jpeg" });

//     // Now use your existing uploader
//     return file;
//   } catch (error) {
//     console.error("Error taking photo:", error);
//     throw error;
//   }
// }

export function NavbarDropdownItem({ status, icon, children, ...props }: NavbarDropdownProps) {
  if ("onClick" in props) {
    return (
      <li>
        <a
          onClick={props.onClick}
          className={classNames(
            "text-base-100 hover:!text-base-200 whitespace-nowrap",
            status === "coming soon" && "opacity-70"
          )}
        >
          <FontAwesomeIcon icon={icon} fixedWidth />
          {children} {status && <span className="italic">{status}</span>}
        </a>
      </li>
    );
  }
  if ("to" in props) {
    return (
      <li>
        <IndicatorNavLink
          indicatorItemClassName="indicator-start top-2 left-3"
          baseClassName="w-full"
          to={props.to}
          className={classNames(
            "whitespace-nowrap",
            "text-base-100 hover:!text-base-200 gap-2 flex justify-start items-center ",
            status === "coming soon" && "opacity-70"
          )}
        >
          <FontAwesomeIcon icon={icon} fixedWidth />
          {children} {status && <span className="italic">{status}</span>}
        </IndicatorNavLink>
      </li>
    );
  }
  return (
    <li>
      <span
        className={classNames(
          "whitespace-nowrap",
          "text-base-100  hover:!text-base-200 gap-2 flex justify-start items-center",
          status === "coming soon" && "opacity-70"
        )}
      >
        <FontAwesomeIcon icon={icon} fixedWidth />
        {children} {status && <span className="italic">{status}</span>}
      </span>
    </li>
  );
}

// Only seems to work on desktop
export function useTriggerAddContentMenu() {
  return () => document.getElementById("add_content_menu_trigger")?.focus();
}

export function AddContentMenu({
  hideMenuLabelsInDesktop,
}: {
  hideMenuLabelsInDesktop?: boolean;
}): React.JSX.Element {
  const [showStatusUpdate, setShowStatusUpdate] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const refetch = useRefetchMyBio();
  const ref = React.useRef<HTMLLabelElement>(null);
  const refetchV2 = useRefetchBioV2();

  const handleStatusClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowStatusUpdate(true); // Simply show the status update
    setIsMenuOpen(false); // Close the menu
  };

  return (
    <>
      <div className="dropdown dropdown-top lg:dropdown-right">
        <label
          ref={ref}
          id="add_content_menu_trigger"
          tabIndex={0}
          className="lg:flex lg:flex-row items-center cursor-pointer"
          onClick={() => setIsMenuOpen(true)}
        >
          <div className="flex flex-col items-center">
            <img src={addButton} alt="Add Content" className="h-12 w-12" />
          </div>
          <span
            className={classNames(
              "max-lg:hidden lg:pl-3 font-bold",
              hideMenuLabelsInDesktop && "hidden"
            )}
          >
            Add Media
          </span>
        </label>

        {isMenuOpen && (
          <div className="fixed inset-0 z-50">
            <div className="fixed inset-0 bg-black/50" onClick={() => setIsMenuOpen(false)} />
            <div className="absolute bottom-16 left-0 right-0 flex justify-center">
              <div className="w-64 bg-zinc-800 rounded-lg overflow-hidden shadow-xl relative z-50">
                <div className="flex items-center justify-between px-4 py-3 border-b border-zinc-700">
                  <span className="text-white text-lg">Add Media</span>
                  <button
                    onClick={() => setIsMenuOpen(false)}
                    className="text-white hover:text-gray-300"
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>

                <div className="divide-y divide-zinc-700">
                  <button
                    className="border-b border-zinc-700 flex items-center justify-between px-4 py-3 text-white hover:bg-zinc-700 cursor-pointer w-full"
                    onClick={handleStatusClick}
                  >
                    <span>Status Update</span>
                    <FontAwesomeIcon icon={faPencil} />
                  </button>

                  <ImageUploader
                    iconOverride={faImages}
                    btnContent={
                      <>
                        Gallery <FontAwesomeIcon icon={faImages} />
                      </>
                    }
                    btnClassName="flex items-center justify-between px-4 py-3 gap-2 w-full !bg-transparent !border-none !shadow-none transition-all text-white"
                    iconClassName="hidden"
                    onComplete={() => {
                      setIsMenuOpen(false);
                      refetchV2?.();
                      refetch?.();
                    }}
                    onError={() => {
                      setIsMenuOpen(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <StatusUpdate
        show={showStatusUpdate}
        setCurrentStatus={() => {
          setShowStatusUpdate(false);
        }}
      />
    </>
  );
}
