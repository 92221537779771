import { GqlOps, useSetStatusMutation } from "shared/dist/__generated__/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faCheck, faGlobe, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "../../../components/atoms/Button";
import { UploadProgress } from "../../../widgets/images";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../../util/routes";
import { createPortal } from "react-dom";
// First create a StatusProgress component
function StatusProgress({
  state,
  onDismiss,
  onViewPost,
}: {
  state: "posting" | "complete" | "error";
  onDismiss?: () => void;
  onViewPost?: () => void;
}) {
  return createPortal(
    <div
      className="fixed inset-0 flex items-center justify-center bg-black/50 z-[1001]"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="bg-[#464752] rounded-lg shadow-xl min-w-[300px] max-w-sm mx-4">
        {state === "posting" && (
          <div className="p-6 text-center">
            <h3 className="text-lg font-medium mb-4 text-base-100">Posting update...</h3>
            <div className="w-16 h-16 rounded-full bg-base-300 animate-spin mx-auto my-4">
              <div className="w-8 h-8 rounded-full bg-primary m-4" />
            </div>
          </div>
        )}

        {state === "complete" && (
          <div className="p-8 text-center">
            <h3 className="text-2xl font-semibold text-base-100">Posted!</h3>
            <div className="w-20 h-20 rounded-full bg-green-500 flex items-center justify-center mx-auto my-6">
              <FontAwesomeIcon icon={faCheck} className="text-base-100 text-3xl" />
            </div>
            <p className="text-base-100/90 text-lg mb-4">Your status has been updated.</p>
            <div className="flex flex-col gap-2">
              <button
                onClick={onViewPost}
                className="text-base-100 hover:text-base-100 text-sm font-medium bg-base-300/30 py-2 px-4 rounded-full"
              >
                View Post
              </button>
              <button
                onClick={onDismiss}
                className="text-base-100/80 hover:text-base-100 text-sm font-medium"
              >
                Dismiss
              </button>
            </div>
          </div>
        )}

        {state === "error" && (
          <div className="p-6 text-center">
            <div className="w-16 h-16 rounded-full bg-error flex items-center justify-center mx-auto">
              <FontAwesomeIcon icon={faXmark} className="text-base-100 text-2xl" />
            </div>
            <h3 className="text-xl font-medium text-base-100 mt-4">Failed to Post</h3>
            <p className="text-base-100/70 mt-2 mb-4">
              There was an error updating your status. Please try again.
            </p>
            <button
              onClick={onDismiss}
              className="text-base-100/80 hover:text-base-100 text-sm font-medium"
            >
              Dismiss
            </button>
          </div>
        )}
      </div>
    </div>,
    document.body
  );
}

// Then modify the StatusUpdate component to use it:
export function StatusUpdate({
  setCurrentStatus,
  show,
}: {
  setCurrentStatus: () => void;
  show: boolean;
}): React.JSX.Element {
  const navigate = useNavigate();
  const [setStatusMutation, { loading }] = useSetStatusMutation();
  const [status, setStatus] = React.useState("");
  const [postState, setPostState] = React.useState<"idle" | "posting" | "complete" | "error">(
    "idle"
  );

  const updateStatus = React.useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (status.trim().length === 0) return;

      try {
        setPostState("posting");
        await setStatusMutation({
          refetchQueries: [
            GqlOps.Query.NewsFeed440,
            GqlOps.Query.BioV2FromSlugFeed403,
            GqlOps.Query.BioV2FromSlugBasics,
            GqlOps.Query.BioFromSlug,
            GqlOps.Query.BioSummaryAbbreviated,
          ],
          variables: { content: status.trim() },
        });
        setPostState("complete");
        setStatus("");
        mixpanel.track("Status update posted");
      } catch (error) {
        console.error("Error updating status:", error);
        setPostState("error");
      }
    },
    [setStatusMutation, status]
  );

  if (!show) return <> </>;

  // Show the progress modal if we're in a posting state
  if (postState !== "idle") {
    return (
      <StatusProgress
        state={postState}
        onDismiss={() => {
          setPostState("idle");
          setCurrentStatus();
        }}
        onViewPost={() => {
          setPostState("idle");
          setCurrentStatus();
          navigate(allRoutes.me.buildPath({}));
        }}
      />
    );
  }

  // Show the regular status update form
  return createPortal(
    <div
      className="fixed inset-0 flex items-center justify-center bg-black/50 z-[1001]"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="bg-[#464752] rounded-2xl shadow-xl w-[500px] mx-4">
        <div className="flex justify-between items-center px-4 py-4">
          <h3 className="text-xl text-base-100">Add a post to your timeline</h3>
          <button
            className="btn btn-sm bg-[#2A2C38] btn-circle text-white border-none"
            onClick={() => setCurrentStatus()}
          >
            <FontAwesomeIcon icon={faXmark} size="lg" />
          </button>
        </div>

        <div className="px-4 pb-4">
          <textarea
            className="w-full bg-white rounded-lg text-base-content p-3 text-lg resize-none focus:outline-none"
            placeholder="What's happening?"
            rows={3}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          />

          <div className="flex justify-between items-center mt-4">
            <button className="flex items-center gap-2 text-base-100 bg-base-300/30 px-3 py-1.5 rounded-full">
              <FontAwesomeIcon icon={faGlobe} size="sm" />
              <span>Public</span>
            </button>

            <Button
              variant="primary"
              className="px-6 py-1.5 bg-[] text-primary-content rounded-full font-medium !w-1/3"
              onClick={updateStatus}
              disabled={loading}
            >
              Post
            </Button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
}
