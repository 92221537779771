import clsx from "clsx";

type SingleFeatureWidgetProps = {
  imgSrc: string;
  right?: boolean;
  children: React.ReactNode;
};

export const SingleFeatureWidget = ({
  imgSrc,
  right = false,
  children,
}: SingleFeatureWidgetProps) => {
  return (
    <div className="relative w-full">
      <div
        className={clsx(
          "flex flex-col-reverse gap-8 items-center justify-center",
          "lg:flex-row lg:items-center lg:justify-between",
          right && "lg:flex-row-reverse"
        )}
      >
        {/* Content section */}
        <div className="w-full lg:w-1/2 flex justify-center">
          <div className="py-10 px-4 max-w-lg">{children}</div>
        </div>

        {/* Image section */}
        <div className="w-full lg:w-1/2 flex justify-center pt-8">
          <img
            src={imgSrc}
            alt="Feature illustration"
            className="w-full h-auto rounded-lg max-w-lg max-h"
          />
        </div>
      </div>
    </div>
  );
};
