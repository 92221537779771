import {
  DatingPrefsUpdate,
  PartnerData,
  useGetDiscoveryPreferencesAndInsertIfNull,
} from "./dating-settings-utilities";
import {
  GqlOps,
  Relationship_Types_Enum,
  useGetDiscoveryPreferences300Query,
  useInsertDiscoveryPreference300Mutation,
  useMyRelationshipsShortV410Query,
  useUpdateDiscoveryPreferences300Mutation,
} from "shared/dist/__generated__/components";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";
import { classNames, filterNulls } from "shared/dist/util";

import { AgeRangeSettings } from "./age-settings";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Container } from "../../../../widgets/container";
import { ContentBox } from "../../../../widgets/content-box";
import { DistanceSettings } from "./distance-settings";
import { GenderPreferences } from "./gender-preferences";
import { H5 } from "shared-web-react/dist/widgets/text";
import React from "react";
import { SpinnerCheckbox } from "shared-web-react/dist/widgets/spinner-checkbox";
import clsx from "clsx";
import { useMustBeLoggedIn } from "../../../../util/routes";
import { ProfileSettingBubble } from "../profile";
import { Text } from "../../../../components/atoms/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// location
// radius
// age range

function PartnerPicker({
  currentPartner,
  partners,
  setCurrentPartner,
}: {
  partners: Array<PartnerData>;
  currentPartner: PartnerData | null;
  setCurrentPartner: (partner: PartnerData | null) => void;
}): React.JSX.Element | null {
  if (!partners?.length) {
    return null;
  }
  return (
    <ContentBox className="px-3 pb-1">
      <H5>Partnered Dating</H5>
      <div className=" my-0 divider" />
      <p className={classNames("text-xs")}>
        Set different preferences for discovering people on your own or with your partner(s).
      </p>
      <div className={classNames("max-w-full overflow-x-auto py-2")}>
        <div className={classNames("join")}>
          <button
            onClick={() => setCurrentPartner(null)}
            className={classNames(
              "btn btn-sm join-item !normal-case",
              !currentPartner && "btn-primary"
            )}
          >
            just me{" "}
          </button>
          {partners.map((partnerData) => (
            <button
              className={classNames(
                "btn btn-sm join-item !normal-case",
                currentPartner?.relationshipId === partnerData.relationshipId && "btn-primary"
              )}
              key={partnerData.relationshipId}
              onClick={() => setCurrentPartner(partnerData)}
            >
              <Avatar slug={partnerData.slug} tailwindSize="4" className="" />
              <span className={classNames("font-bold")}>{"@" + partnerData.slug}</span>
            </button>
          ))}
        </div>
      </div>
    </ContentBox>
  );
}

export function DatingSettings(): React.JSX.Element {
  const [currentPartner, setCurrentPartner] = React.useState<{
    slug: string;
    relationshipId: string;
  } | null>(null);
  const my_id = useMustBeLoggedIn();
  // const [justShowEveryone, setJustShowEveryone] = React.useState(false);
  const relationshipsQuery = useMyRelationshipsShortV410Query({
    variables: { my_id: my_id!, type: Relationship_Types_Enum.Partner },
    skip: !my_id,
  });
  const partners = filterNulls(
    (relationshipsQuery.data?.relationships_union ?? []).map((p) =>
      p?.snd_user_summary?.slug && p?.id
        ? { slug: p.snd_user_summary.slug, relationshipId: p.id }
        : null
    )
  );
  const id = currentPartner?.relationshipId ?? my_id!;
  const { data: prefsData, ...prefsQuery } = useGetDiscoveryPreferences300Query({
    variables: {
      id: currentPartner ? currentPartner.relationshipId : id,
    },
  });
  const prefs = prefsData?.discovery_preferences?.[0] || null;
  const [updatePrefs, { loading: updateLoading }] = useUpdateDiscoveryPreferences300Mutation();
  const [insertPreferences, mutationResults] = useInsertDiscoveryPreference300Mutation();

  const onUpdate = React.useCallback(
    async (update: DatingPrefsUpdate) => {
      if (prefsQuery.loading || mutationResults.loading) return;
      if (!my_id) return;

      if (!prefs && !mutationResults.called) {
        await insertPreferences({
          variables: {
            object: currentPartner?.relationshipId
              ? { relationship_id: currentPartner.relationshipId, ...update }
              : { user_id: my_id, ...update },
          },
          refetchQueries: [
            GqlOps.Query.GetAllDiscoveryPreferences300,
            GqlOps.Query.GetDiscoveryPreferences300,
            GqlOps.Query.DiscoveryResults542,
          ],
          awaitRefetchQueries: true,
        });
      } else {
        await updatePrefs({
          variables: { id: my_id!, update },
          refetchQueries: [
            GqlOps.Query.GetAllDiscoveryPreferences300,
            GqlOps.Query.GetDiscoveryPreferences300,
            GqlOps.Query.DiscoveryResults542,
          ],
          awaitRefetchQueries: true,
        });
      }
    },
    [
      updatePrefs,
      insertPreferences,
      my_id,
      prefs,
      currentPartner,
      mutationResults.loading,
      prefsQuery.loading,
    ]
  );

  const toggleIncludeUnverified = React.useCallback(
    async () => onUpdate({ include_unverified_users: !prefs?.include_unverified_users }),
    [prefs, onUpdate]
  );
  const toggleIncludeExisting = React.useCallback(
    async () => onUpdate({ include_existing_connections: !prefs?.include_existing_connections }),
    [prefs, onUpdate]
  );
  const toggleShowEveryone = React.useCallback(async () => {
    if (!currentPartner) {
      await onUpdate({ just_show_everyone: !prefs?.just_show_everyone });
    }
  }, [prefs?.just_show_everyone, onUpdate, currentPartner]);

  const loading = relationshipsQuery.loading || prefsQuery.loading || updateLoading;
  const disabled = loading || !prefs || prefs.just_show_everyone;

  return (
    <div className={classNames("space-y-3 w-full mb-10")}>
      {/* <ContentBox className="py-3 space-y-2 !bg-[#2A2C38] text-white"> */}
      <Text as="h3" size="xs" weight="semibold" className="pt-2">
        Filter & Show
      </Text>
      <ProfileSettingBubbleChecked
        text="Show me everyone"
        prefs={prefs ? { just_show_everyone: prefs.just_show_everyone } : undefined}
        handleClick={() =>
          new Promise((resolve, reject) => {
            if (!my_id) return resolve(null);
            toggleShowEveryone().then(resolve).catch(reject);
          })
        }
      />

      <DistanceSettings {...{ prefs, onUpdate, disabled }} />

      <AgeRangeSettings
        partner={currentPartner}
        key={currentPartner?.relationshipId}
        prefs={prefs}
        disabled={disabled}
        onUpdate={onUpdate}
      />

      <div className={clsx("divider dvider-horizontal my-2")} />

      <ProfileSettingBubbleChecked
        text="Include Unverified Users"
        prefs={prefs ? { just_show_everyone: prefs.include_unverified_users } : undefined}
        handleClick={() =>
          new Promise((resolve, reject) => {
            if (!my_id) return resolve(null);
            toggleIncludeUnverified().then(resolve).catch(reject);
          })
        }
      />
      <ProfileSettingBubbleChecked
        text="Include Existing Connections"
        prefs={prefs ? { just_show_everyone: prefs.include_existing_connections } : undefined}
        handleClick={() =>
          new Promise((resolve, reject) => {
            if (!my_id) return resolve(null);
            toggleIncludeExisting().then(resolve).catch(reject);
          })
        }
      />

      <GenderPreferences
        onUpdate={onUpdate}
        hasPartners={!!partners?.length}
        prefs={prefs}
        disabled={disabled}
        key={currentPartner?.relationshipId}
        partner={currentPartner}
      />
    </div>
  );
}

export function DatingSettingsContainer(): React.JSX.Element {
  return (
    <Container size="xs" className={classNames("flex flex-col h-full pb-4 items-stretch")}>
      <DatingSettings />
    </Container>
  );
}

export function ProfileSettingBubbleChecked({
  text,
  handleClick,
  prefs,
  currentPartner,
}: {
  text: string;
  handleClick?: () => void;
  prefs?: { just_show_everyone: boolean };
  currentPartner?: PartnerData | null;
}): React.JSX.Element {
  const handleChange = async () => {
    if (!currentPartner && handleClick) await handleClick();
  };

  return (
    <div className="flex justify-between items-center py-2 bg-[#464752] rounded-lg px-4 cursor-pointer">
      <Text as="p" size="xxs" className="text-white font-medium">
        {text}
      </Text>
      <SpinnerCheckbox
        checked={!!prefs?.just_show_everyone}
        disabled={prefs?.just_show_everyone}
        onChangeWrapped={handleChange}
      />
    </div>
  );
}
