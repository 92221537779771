import {
  NavStackViewHeader,
  usePageTitle,
  useSetPageTitleFxn,
  useShouldSuppressNavStackHeaderState,
} from "shared-web-react/dist/widgets/nav-stack-view";
import React, { useContext } from "react";

import { AddContentMenu } from "../../widgets/bio/nav-dropdowns";
import { IndicatorNavLink } from "../../widgets/indicator-nav-link";
import { SignUpTrigger } from "./signup-triggers";
import { UserNavbar } from "./top-navbar";
import { UserPageContainer } from "./common";
import { allRoutes } from "../../util/routes";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useMyId } from "shared/dist/auth-data";
import { useMyInfo } from "../bio/bio-settings";
import { useSwipeable } from "react-swipeable";
import feed from "../../assets/Feed.png";
import feedActive from "../../assets/Feed-Active.png";
import events from "../../assets/Events.png";
import eventsActive from "../../assets/Events-Active.png";
import chat from "../../assets/Chat.png";
import chatActive from "../../assets/Chat-Active.png";
import discovery from "../../assets/Discovery.png";
import discoveryActive from "../../assets/Discovery-Active.png";
import { Capacitor } from "@capacitor/core";
import { SimmerZeroScreen } from "../../widgets/zero-screens";
import { Text } from "../../components/atoms/Text";
import { LazyImage } from "shared-web-react/dist/widgets/lazy-image";
import simmrLogo from "../../assets/simmr-large-preview.png";
import appStoreButton from "../../assets/app-store-button.svg";

const LeftMarginContext = React.createContext(0);
export function useLeftMargin() {
  return useContext(LeftMarginContext) + "px";
}

export type UserPageNavWrapperProps = React.PropsWithChildren<{
  hideMenuLabelsInDesktop?: boolean;
  newContentBoxUi?: boolean;
  contentClassName?: React.HTMLProps<HTMLDivElement>["className"];
  noWrap?: boolean;
}>;

export function UserPageNavWrapper({
  children,
  hideMenuLabelsInDesktop,
  newContentBoxUi,
  contentClassName,
  noWrap,
}: UserPageNavWrapperProps): React.JSX.Element {
  const my_id = useMyId();
  const { slug } = useMyInfo();
  const suppressNavStack = useShouldSuppressNavStackHeaderState({ slug });
  const pageTitle = usePageTitle();
  const [left, setLeft] = React.useState(0);
  const [bottom, setBottom] = React.useState(0);
  const topNavRef = React.useRef<HTMLDivElement>(null);
  const labelRef = React.useRef<HTMLLabelElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isNonMobileScreen, setIsNonMobileScreen] = React.useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    const handleResize = () => {
      setIsNonMobileScreen(window.innerWidth > 768); // Adjust breakpoint as needed
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        topNavRef.current &&
        !topNavRef.current.contains(event.target as Node) &&
        labelRef.current &&
        !labelRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const setTitle = useSetPageTitleFxn();

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] <= 50 && eventData.dir === "Right" && eventData.deltaX > 100) {
        setTitle(null);
        window.history.back();
      }
    },
  });

  return (
    <LeftMarginContext.Provider value={left}>
      <div className="relative flex flex-col h-screen max-w-full">
        {/* Navbar with safe area padding */}
        <div
          ref={topNavRef}
          className="fixed px-4 top-0 w-full z-50 flex items-center justify-between bg-[#2A2C38] text-white"
          style={{
            paddingTop: "calc(max(env(safe-area-inset-top, 0), 16px))",
            height: "calc(56px + env(safe-area-inset-top, 0))",
          }}
        >
          {suppressNavStack ? (
            <NavStackViewHeader topSize="5">{pageTitle ?? ""}</NavStackViewHeader>
          ) : (
            <UserNavbar />
          )}
        </div>

        {/* Main content */}
        <div
          className="flex-1 overflow-y-auto bg-[#2A2C38]"
          style={{
            paddingTop: "calc(env(safe-area-inset-top, 0) + 56px)", // Dynamic safe area + fixed navbar height
          }}
        >
          <div
            className="relative h-full"
            style={{
              paddingBottom: "calc(env(safe-area-inset-bottom, 0) + 70px)",
            }}
          >
            {isNonMobileScreen ? (
              // <SimmerZeroScreen
              //   header="Simmr is best on mobile."
              //   btnText="Download the app"
              //   text="Download the app or visit us on your phone to get the best experience."
              //   clickHandler={() => {
              //     navigate("https://apps.apple.com/us/app/candid-enm/id6466639507");
              //   }}
              // />
              <>
                {" "}
                <div
                  className="flex flex-col items-center h-full"
                  style={{
                    backgroundImage: `url(${simmrLogo})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Text as="h1" size="md" weight="semibold" className="text-white my-48">
                    The Full Simmr Experience Awaits on Mobile!
                  </Text>
                  {/* <LazyImage
                src={}
                alt="Phone"
                className="w-64 h-64 mt-4"
              /> */}
                  <Text
                    as="h1"
                    size="md"
                    weight="semibold"
                    className="text-white mt-62 pt-52 md:max-w-[80%] lg:max-w-[70%] xl:max-w-[60%] "
                  >
                    Join events, chat with friends,and explore your ENM community - all on the go!
                  </Text>
                  <div className="flex">
                    <a
                      className="pt-10"
                      href="https://apps.apple.com/us/app/candid-enm/id6466639507"
                    >
                      <img src={appStoreButton} alt="App Store" height={100} width={200} />
                    </a>
                  </div>
                  <Text as="p" size="xs" weight="semibold" className="text-white pt-10 px-[10%] ">
                    Not an Apple user? Hang on tight! Out Android version is in the works.
                  </Text>
                </div>
              </>
            ) : (
              children
            )}{" "}
          </div>
        </div>

        {/* Bottom navigation */}
        {!isNonMobileScreen && my_id ? (
          <div
            className="bottom-0 left-0 right-0 w-full bg-[#2A2C38]"
            style={{
              paddingBottom: "env(safe-area-inset-bottom)",
            }}
          >
            <BottomOrSideNav {...{ setLeft, setBottom, hideMenuLabelsInDesktop }} />
          </div>
        ) : (
          <SignUpTrigger />
        )}
      </div>
    </LeftMarginContext.Provider>
  );
}

interface NavItem {
  icon: string;
  activeIcon: string;
  label: string;
  route: string;
  matchRoute?: (path: string) => boolean;
}

interface BottomOrSideNavProps {
  setBottom: (n: number) => void;
  setLeft: (n: number) => void;
  hideMenuLabelsInDesktop?: boolean;
}

export const NavIcon = ({
  normalSrc,
  activeSrc,
  isActive,
  label,
  isMobile,
}: {
  normalSrc: string;
  activeSrc: string;
  isActive: boolean;
  label: string;
  isMobile: boolean;
}) => {
  if (isMobile) {
    return (
      <div className="flex flex-col items-center">
        <img src={isActive ? activeSrc : normalSrc} alt={label} className="w-6 h-6" />
        <span
          className={clsx(
            "text-sm mt-1",
            isActive ? (label === "Discover" ? "text-[#FFC738]" : "text-[#FF424D]") : "text-white"
          )}
        >
          {label}
        </span>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-3">
      <img src={isActive ? activeSrc : normalSrc} alt={label} className="w-6 h-6" />
    </div>
  );
};

const BottomOrSideNav: React.FC<BottomOrSideNavProps> = ({
  setLeft,
  setBottom,
  hideMenuLabelsInDesktop,
}) => {
  const leftRef = React.useRef<HTMLDivElement>(null);
  const location = useLocation();
  const my_id = useMyId();

  const isRouteActive = React.useCallback(
    (route: string, matchRoute?: (path: string) => boolean) => {
      // Always use the matchRoute function if provided
      if (matchRoute) {
        return matchRoute(location.pathname);
      }

      // Specific handling for home route
      if (route === allRoutes.HOME.buildPath({})) {
        return (
          (location.pathname === route ||
            location.pathname === "/home" ||
            location.pathname === "/") &&
          !location.pathname.includes("discovery")
        );
      }

      // Default behavior for other routes - keep original logic
      return location.pathname.startsWith(route);
    },
    [location.pathname]
  );

  const navItems: NavItem[] = React.useMemo(
    () => [
      {
        icon: feed,
        activeIcon: feedActive,
        label: "Feed",
        route: allRoutes.HOME.buildPath({}),
        matchRoute: (path) => {
          return (
            (path === "/" || path === "/home" || path === allRoutes.HOME.buildPath({})) &&
            !location.pathname.includes("discovery")
          );
        },
      },
      {
        icon: discovery,
        activeIcon: discoveryActive,
        label: "Discover",
        route: allRoutes.DISCOVERY.buildPath({}),
        matchRoute: (path) => location.pathname.includes("discovery"),
      },
      {
        icon: events,
        activeIcon: eventsActive,
        label: "Events",
        route: allRoutes.EVENTS.buildPath({}),
        matchRoute: (path) => location.pathname.includes("events"),
      },
      {
        icon: chat,
        activeIcon: chatActive,
        label: "Chat",
        route: allRoutes.MESSAGING.buildPath({}),
        matchRoute: (path) => location.pathname.includes("threads"),
      },
    ],
    [location.pathname]
  );

  if (!my_id) return null;

  return (
    <>
      {/* Desktop Left Sidebar */}
      <div
        ref={leftRef}
        className="UserPageMainNav bg-[#2A2C38] z-35-nav-sidebar border-r border-r-primary max-lg:hidden lg:pt-16 text-primary fixed bottom-0 left-0 top-0"
      >
        <div className="flex flex-col space-y-3 justify-start items-start">
          <ul className="menu font-semibold p-0">
            {navItems.map((item, idx) => (
              <React.Fragment key={idx}>
                <li>
                  <IndicatorNavLink
                    baseClassName="w-full"
                    to={item.route}
                    className="indicator w-full gap-3 items-center"
                    indicatorItemClassName="indicator-middle indicator-start badge badge-sm max-lg:badge-xs"
                  >
                    <NavIcon
                      normalSrc={item.icon}
                      activeSrc={item.activeIcon}
                      isActive={isRouteActive(item.route, item.matchRoute)}
                      label={item.label}
                      isMobile={false}
                    />
                    {!hideMenuLabelsInDesktop && (
                      <span
                        className={
                          isRouteActive(item.route, item.matchRoute) ? "text-primary font-bold" : ""
                        }
                      >
                        {item.label}
                      </span>
                    )}
                  </IndicatorNavLink>
                </li>
                {idx === 1 && (
                  <li>
                    <AddContentMenu hideMenuLabelsInDesktop={hideMenuLabelsInDesktop} />
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>

      {/* Mobile Bottom Navigation */}
      <div className="shadow-xl shadow-white fixed bottom-0 w-full left-0 right-0 z-45-nav-sidebar overflow-hidden lg:hidden">
        <div className="bg-[#2A2C38] rounded-t-lg pb-safe  border-t border-[#1E1F28]/20">
          <div className="h-0.5 w-full bg-gradient-to-b from-[#1E1F28]/20 to-transparent" />
          <UserPageContainer
            className="flex flex-row justify-between items-center px-6"
            onHeight={setBottom}
          >
            {[...navItems.slice(0, 2), null, ...navItems.slice(2)].map((item, idx) => (
              <React.Fragment key={idx}>
                {item === null ? (
                  <AddContentMenu hideMenuLabelsInDesktop={hideMenuLabelsInDesktop} />
                ) : (
                  <IndicatorNavLink
                    to={item.route}
                    className="flex flex-col items-center min-w-[40px]"
                  >
                    <NavIcon
                      normalSrc={item.icon}
                      activeSrc={item.activeIcon}
                      isActive={isRouteActive(item.route, item.matchRoute)}
                      label={item.label}
                      isMobile={true}
                    />
                  </IndicatorNavLink>
                )}
              </React.Fragment>
            ))}
          </UserPageContainer>
        </div>
      </div>
    </>
  );
};
